
import { Image, Users } from 'react-feather';
import { Trans } from 'react-i18next';

import { darkenColor, getAccessibleColor } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { CampaignType, Charity, Event, PublicCampaign, PublicProject } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { ProgressBar } from '../../Shared/UI/ProgressBar';
import { defaultColor } from '../../Shared/useCharityStyle';

export default function CampaignCard({
  campaign,
  onClick,
}: {
  campaign: PublicCampaign & {
    project: PublicProject;
    event: Event | null;
    charity: Charity;
  };
  onClick?: () => void;
}) {
  const { formatCurrency } = useLocale();

  const imageUrl = campaign.image_url || campaign.project.image_url || campaign.charity.image_url;

  const brandColor = campaign.charity.brand_color || defaultColor;
  const complimentaryColor = getAccessibleColor(darkenColor(brandColor, 0.25));

  return (
    <Card
      link={{
        onClick,
        href: onClick ? undefined : campaign.url,
        ariaLabel: campaign.title,
      }}
      spacing="none"
      shadow={false}
      border={false}
    >
      <div className="rounded aspect-video relative">
        {campaign.charity.logo_url && (
          <div className="absolute top-3 right-3 p-2 rounded bg-white z-[3] shadow-sm">
            <img src={campaign.charity.logo_url} alt={campaign.charity.title} className="h-5" />
          </div>
        )}
        {imageUrl && (
          <img src={imageUrl} alt={campaign.title} className="object-cover w-full h-full rounded-md" />
        )}
        {!imageUrl && (
          <>
            <div className="absolute inset-0 rounded-lg opacity-10 z-[2]"
              style={{ background: brandColor }}
            />
            <div className="w-full h-full flex items-center justify-center">
              <Icon className="text-4xl text-black opacity-10 z-[2]">
                <Image strokeWidth={1} />
              </Icon>
            </div>
          </>
        )}
      </div>
      <div className="min-w-0 max-w-full space-y-2 mt-4 sm:pb-2">
        <div className="flex space-x-2">
          {campaign.type === CampaignType.Team && (
            <div
              className="rounded-full w-5 h-5 text-white text-xs flex items-center justify-center my-0.5 shrink-0"
              style={{
                background: brandColor,
                color: complimentaryColor,
              }}
            >
              <Icon>
                <Users />
              </Icon>
            </div>
          )}
          <h3 className="text-lg min-h-14">
            {campaign.title}
          </h3>
        </div>

        <ProgressBar
          current={campaign.funds_raised}
          target={campaign.fundraising_target}
          brandColor={campaign.charity.brand_color}
        />

        <div className="min-h-[52px]">
          <Trans
            i18nKey="frontend:progress_raised_for"
            values={{ amount: formatCurrency(campaign.funds_raised), charity: campaign.charity.title }}
          >
            <strong className="font-bold text-slate-900" />
          </Trans>
        </div>

        <div>
          <span className="bg-slate-100 text-sm p-1 text-slate-600 font-medium leading-tight rounded-sm">
            {campaign.project.title}
          </span>
        </div>
      </div>
    </Card>
  );
}
