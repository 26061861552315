import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { SharedProps } from '../types';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import { Background } from './Background';

export default function ErrorPage({
  contentNav,
  auth: {
    user,
  },
}: SharedProps) {
  const { t } = useTranslation();

  return (
    <>
      <NavBar navItems={contentNav} user={user} />

      <Background />

      <div className="relative z-2">
        <Container spacing="lg" className="min-h-[50vh] flex items-center justify-center">
          <div className="text-center">
            <SupportaSymbol className="text-slate-200 w-12 mx-auto mb-4" />
            <h2 className="text-xl font-semibold">
              {t('shared:errors.not_found')}
            </h2>
          </div>
        </Container>

        <Footer links={contentNav} />
      </div>
    </>
  );
}

ErrorPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
