import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import { Edit, Globe, PlusSquare } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Campaign, User } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';
import { useRouter } from '../router';
import Container from '../UI/Container';

export function UserNav({
  campaign,
  user,
}: {
  campaign: Campaign;
  user: User;
}) {
  const { t } = useTranslation();
  const { routes, isActive } = useRouter();

  return (
    <div className="bg-slate-50 border-y border-slate-200">
      <Container className="!pt-6 !pb-0 !space-y-4">
        <div className="text-slate-600 text-sm">
          {t('frontend:user_welcome_to_campaign', { user: user.first_name })}
        </div>
        <div className="flex space-x-4">
          <Link
            href={routes.campaign_page(campaign.id)}
            className={classNames(
              'py-2 px-0.5 rounded-t font-medium text-slate-700 border-b-[3px] border-transparent mb-[-1px] hover:text-slate-800 hover:border-project',
              isActive(routes.campaign_page(campaign.id), true) && '!text-slate-800 !border-project'
            )}
          >
            <Icon className="mr-1.5">
              <Globe />
            </Icon>
            {t('frontend:campaign')}
          </Link>
          <Link
            href={routes.edit_campaign_page(campaign.id)}
            className={classNames(
              'py-2 px-0.5 rounded-t font-medium text-slate-700 border-b-[3px] border-transparent mb-[-1px] hover:text-slate-800 hover:border-project',
              isActive(routes.edit_campaign_page(campaign.id)) && '!text-slate-800 !border-project'
            )}
          >
            <Icon className="mr-1.5">
              <Edit />
            </Icon>
            {t('frontend:edit')}
          </Link>
          <Link
            href={routes.campaign_products_page(campaign.id)}
            className={classNames(
              'py-2 px-0.5 rounded-t font-medium text-slate-700 border-b-[3px] border-transparent mb-[-1px] hover:text-slate-800 hover:border-project',
              isActive(routes.campaign_products_page(campaign.id)) && '!text-slate-800 !border-project'
            )}
          >
            <span className="flex items-center">
              <Icon className="mr-1.5">
                <PlusSquare />
              </Icon>
              {t('frontend:products')}
            </span>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export function getOnboardingStatus(campaign: Campaign & { donations_count: number; }) {
  const hasFinishedProfile = !!campaign.image_url && !!campaign.description;
  const hasDonations = campaign.donations_count > 0;

  return {
    hasFinishedProfile,
    hasDonations,
  };
}
