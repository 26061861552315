import { router } from '@inertiajs/core';
import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Calendar, Check, ChevronRight, Heart, Image, Share, UserPlus, Users } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { dispatchNewCampaignEvent, dispatchNewDonationEvent } from '../../Shared/eventTracking';
import { useLocale } from '../../Shared/locale';
import { Modal } from '../../Shared/Modal/Modal';
import { Campaign, CampaignType, Charity, Donation, Event, PaymentStatus, Product, Project, User } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { ProgressBar } from '../../Shared/UI/ProgressBar';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useGaEvents } from '../../Shared/useGaEvents';
import EventCard from '../Event/EventCard';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { Button, LinkButton } from '../UI/Button';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { EmbedContext } from '../UI/EmbedProvider';
import { Footer } from '../UI/Footer';
import Header from '../UI/Header';
import { NavBar } from '../UI/NavBar';
import PageOfflineWarning from '../UI/PageOfflineWarning';
import CampaignCard from './CampaignCard';
import JoinTeamForm from './JoinTeamForm';
import { OnboardingSteps } from './OnboardingSteps';
import ShareOptions from './ShareOptions';
import { UserNav } from './UserNav';

const DONATIONS_PER_PAGE = 10;

export interface CampaignPageProps extends SharedProps {
  campaign: Campaign & {
    project: Project & {
      event: Event | null;
    };
    active_products: Product[];
    optional_products: Product[];
    team: Campaign | null;
    members: Campaign[];
    allow_join: boolean;
    donations: Donation[];
    donations_count: number;
    user: User | null;
    document_title: string;
  };
  newCampaign: boolean;
  onboarding: boolean;
  donation?: Donation;
  potentialTeamMembers?: (Campaign & {
    project: Project;
  })[];
  charity: Charity;
}

export default function CampaignPage({
  campaign,
  newCampaign,
  onboarding,
  donation,
  potentialTeamMembers = [],
  auth: { user },
  charity,
  params,
}: CampaignPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { formatCurrency, formatRelativeDate, formatDate, formatNumber } = useLocale();
  const { isEmbedded } = useContext(EmbedContext);

  const activeProducts = campaign.active_products.filter((product) => !product.required);

  const [limit, setLimit] = useState(DONATIONS_PER_PAGE);
  const [loading, setLoading] = useState(false);
  const donations = campaign.donations;
  const hasMoreDonations = donations.length < campaign.donations_count;

  /**
   * Use "infinite" pagination style to append new donations to the list.
   */
  const loadMoreDonations = () => {
    setLoading(true);
    const newLimit = Math.min(limit + DONATIONS_PER_PAGE, campaign.donations_count);
    const url = routes.campaign_page(campaign.id);

    // Visit the next page of donations
    router.get(`${url}?limit=${newLimit}`, {}, {
      only: ['campaign'],
      preserveScroll: true,
      preserveState: true,
      replace: true,
      onSuccess: () => {
        // To hide the `limit` parameter in the URL, we need to replace the current history state.
        window.history.replaceState({}, '', url);
        setLimit(newLimit);
        setLoading(false);
      },
    });
  };

  useCharityStyle(charity);

  const project = campaign.project;
  const imageUrl = campaign.image_url || project.image_url || charity.image_url;

  const isLoggedInAsOwner = user && user.id === campaign.user?.id;

  const fundraisingTarget = campaign.fundraising_target || 0;
  const hasFundraisingTarget = fundraisingTarget > 0;

  // Instantly open the share modal if the param is present in the URL.
  const [showShareModal, setShowShareModal] = useState('share' in params);
  const [showJoinTeamModal, setShowJoinTeamModal] = useState('join_team' in params);

  // Removes the share/join_team param from the URL after initially opening the share modal.
  useEffect(() => {
    if ('share' in params || 'join_team' in params) {
      // Instantly removing the search param doesn't work, hence the timeout.
      const timer = window.setTimeout(() => {
        const url = new URL(window.location.href);
        url.searchParams.delete('share');
        url.searchParams.delete('join_team');

        window.history.replaceState({}, '', url.toString());
      }, 500);

      return () => window.clearTimeout(timer);
    }
  }, [params]);

  // Google Analytics tracking for new donations and new campaigns.
  useGaEvents(charity.google_analytics_id);
  useGaEvents(window.Supporta?.googleAnalyticsId);

  // Dispatch event when the campaign was just created.
  useEffect(() => {
    if (newCampaign) {
      dispatchNewCampaignEvent({
        charity,
        project: campaign.project,
        campaign,
      });
    }
  }, [newCampaign]);

  // Dispatch event when a donation is accepted.
  useEffect(() => {
    if (donation?.active || donation?.payment_status === PaymentStatus.PENDING) {
      dispatchNewDonationEvent({
        charity,
        project: campaign.project,
        campaign,
        donation: {
          ...donation,
          frequency: 'oneoff',
        },
      });
    }
  }, [donation]);

  const event = project.event;
  const charityUrl = charity.public ? routes.home_page(charity.slug, event?.slug) : null;

  return (
    <>
      <NavBar charity={charity} user={user} />

      {isLoggedInAsOwner && (
        <UserNav campaign={campaign} user={user} />
      )}

      <Header className={classNames(campaign.fundraising_target && campaign.fundraising_target > 0 && '!border-none')}>
        <Container>
          {onboarding && (
            <OnboardingSteps campaign={campaign} charity={charity} isLoggedInAsOwner={!!isLoggedInAsOwner} />
          )}

          {(!campaign.enabled || !campaign.project.enabled) && (
            <PageOfflineWarning />
          )}

          {donation && (
            <>
              {donation.active && (
                <Card shadow={false} className="bg-white border-2 border-emerald-500/30">
                  <h4 className="flex items-center gap-4 mb-4 text-base font-bold text-emerald-600">
                    <span className="flex items-center justify-center flex-shrink-0 w-5 h-5 text-white rounded-full bg-emerald-600">
                      <Check strokeWidth={3} className="w-3 h-3" />
                    </span>
                    {campaign.donation_message ? t('frontend:message_from_campaigner', { campaigner: campaign.campaigner }) : t('frontend:donation_success')}
                  </h4>
                  <div className="text-lg whitespace-pre-line">
                    {campaign.donation_message || campaign.project.donation_message || t('frontend:donation_thank_you_message')}
                  </div>
                </Card>
              )}
              {!donation.active && donation.payment_status === PaymentStatus.PENDING && (
                <Card shadow={false} className="font-medium bg-white border-2 border-amber-500">
                  {t('frontend:donation_pending')}
                </Card>
              )}
              {!donation.active && donation.payment_status !== PaymentStatus.PENDING && (
                <Card shadow={false} className="font-medium bg-white border-2 border-red-500">
                  {t('frontend:donation_failed')}
                </Card>
              )}
            </>
          )}

          <div>
            {campaign.type === CampaignType.Individual && campaign.team && (
              <Link
                href={routes.campaign_page(campaign.team.id)}
                className="flex items-center mb-1 space-x-2 font-medium text-slate-600 hover:text-slate-900"
              >
                <div className="flex items-center justify-center w-6 h-6 my-1 text-xs rounded-full bg-project text-project-comp shrink-0">
                  <Icon>
                    <Users />
                  </Icon>
                </div>
                <span>
                  {campaign.team.title}
                </span>
                <Icon>
                  <ChevronRight />
                </Icon>
              </Link>
            )}

            <h1 className="flex mb-1 space-x-2 text-3xl font-extrabold text-slate-800">
              {campaign.type === CampaignType.Team && (
                <div className="mr-1 rounded-full w-8 h-8 bg-project text-project-comp !text-md flex items-center justify-center my-1 shrink-0">
                  <Icon className="!text-base">
                    <Users />
                  </Icon>
                </div>
              )}
              <div>
                {campaign.title}
              </div>
            </h1>

            <div className="flex flex-wrap text-slate-600">
              {(campaign.type === 'individual' || campaign.company_name) && campaign.campaigner !== campaign.title && (
                <span className="mt-1 mr-3 whitespace-nowrap">
                  {campaign.campaigner}
                </span>
              )}
              {' '}
              {charityUrl && (
                <a
                  href={charityUrl}
                  data-supporta-embed={false}
                  className="mt-1 mr-3 whitespace-nowrap"
                  target={isEmbedded ? '_blank' : undefined}
                  rel={isEmbedded ? 'noopener noreferrer' : undefined}
                >
                  {t('frontend:campaign_for_charity', { charity: charity.title })}
                </a>
              )}
              {' '}
              <span className="p-1 mt-1 text-sm font-medium bg-slate-100 text-slate-600 whitespace-nowrap">
                {project.title}
              </span>
            </div>
          </div>

          <div className="relative flex flex-col items-center justify-center overflow-hidden bg-white rounded-2xl aspect-video">
            {imageUrl && (
              <img src={imageUrl} alt={campaign.title} className="object-cover w-full h-full" />
            )}
            {!imageUrl && (
              <div className="absolute inset-0 bg-gradient-to-tl from-project/15 to-project/10 z-[3]" />
            )}
            {!imageUrl && (
              <Icon className="text-6xl text-black opacity-10 z-[2]">
                <Image strokeWidth={1} />
              </Icon>
            )}
          </div>

          <div>
            {hasFundraisingTarget && (
              <Trans
                i18nKey="frontend:progress_raised_of_target"
                values={{
                  amount: formatCurrency(campaign.funds_raised),
                  target: formatCurrency(fundraisingTarget),
                }}
              >
                <strong className="text-xl font-bold text-slate-900" />
              </Trans>
            )}
            {!hasFundraisingTarget && (
              <Trans
                i18nKey="frontend:progress_raised"
                values={{
                  amount: formatCurrency(campaign.funds_raised),
                }}
              >
                <strong className="text-xl font-bold text-slate-900" />
              </Trans>
            )}
          </div>
        </Container>
      </Header>

      {campaign.fundraising_target !== null && campaign.fundraising_target > 0 && (
        <ProgressBar
          current={campaign.funds_raised}
          target={campaign.fundraising_target}
          brandColor={charity.brand_color}
          rounded={false}
          className="-mt-1"
        />
      )}

      <Container>
        {!project.ended && (
          <>
            <div className="space-y-6">
              <div className={classNames('grid grid-cols-[1fr_1fr] gap-4')}>
                <LinkButton
                  href={routes.donation_page(campaign.id)}
                  size="lg"
                  className="!font-bold"
                >
                  <Icon className="mr-2">
                    <Heart />
                  </Icon>
                  {t('frontend:donate')}
                </LinkButton>
                <Button
                  onClick={() => setShowShareModal(true)}
                  size="lg"
                  variant="secondary"
                >
                  <Icon className="mr-2">
                    <Share />
                  </Icon>
                  {t('frontend:share')}
                </Button>
                <Modal isOpen={showShareModal} onClose={() => setShowShareModal(false)} size="sm">
                  <ShareOptions campaign={campaign} charity={charity} />
                </Modal>
              </div>
              {activeProducts.length > 0 && (
                <div className="space-y-4">
                  <div className="space-y-2">
                    <h4 className="font-medium text-slate-500">
                      {t('frontend:order_now')}
                    </h4>
                    {activeProducts.map((product) => (
                      <Card
                        link={product.max_per_order > 0 ? {
                          href: `${routes.donation_page(campaign.id)}?product=${product.id}`,
                          ariaLabel: product.title,
                        } : undefined}
                        spacing="xs"
                        className="!py-3"
                        border
                        key={product.id}
                      >
                        <div className={classNames(
                          'grid items-center gap-2',
                          product.image_url ? 'grid-cols-[30px_1fr_fit-content(100px)_16px]' : 'grid-cols-[1fr_fit-content(100px)_16px]',
                          product.max_per_order === 0 && 'opacity-60',
                        )}>
                          {product.image_url && (
                            <div className="w-[30px] h-[30px]">
                              <img src={product.image_url} alt={product.title} className="block object-cover w-full h-full rounded" />
                            </div>
                          )}
                          <div>
                            <strong>
                              {product.title}
                            </strong>
                          </div>
                          <div className="text-right">
                            {product.max_per_order > 0 ? formatCurrency(product.selling_price) : (
                              <div className="px-1 text-sm rounded-sm bg-slate-100">
                                {t('frontend:sold_out')}
                              </div>
                            )}
                          </div>
                          <div>
                            <Icon>
                              <ChevronRight />
                            </Icon>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <hr />
          </>
        )}

        <div className="space-y-4">
          <h2 className="text-xl">
            {t('frontend:about_this_campaign')}
          </h2>

          <div className="space-y-1 text-slate-500">
            {campaign.start_date && (
              <div className="flex space-x-3">
                <div>
                  <Icon>
                    <Calendar />
                  </Icon>
                </div>
                <div>
                  {formatDate(campaign.start_date, 'display_date')}
                  {campaign.end_date && (
                    <>
                      {' '}&ndash;{' '}{formatDate(campaign.end_date, 'display_date')}
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="flex space-x-3">
              <div>
                <Icon>
                  <UserPlus />
                </Icon>
              </div>
              <div>
                {t('frontend:campaign_created_on_date', {
                  name: campaign.company_name || campaign.campaigner,
                  date: formatDate(campaign.created_at),
                })}
              </div>
            </div>
          </div>

          <div className="text-lg whitespace-pre-line">
            {campaign.description || (campaign.type === CampaignType.Individual ? charity.default_individual_description : charity.default_team_description)}
          </div>
        </div>

        {campaign.team && (
          <>
            <hr />

            <div className="space-y-4">
              <div className="flex justify-between">
                <h2 className="text-xl font-bold">
                  {t('frontend:team')}
                </h2>
              </div>

              <CampaignCard
                campaign={{
                  ...campaign.team,
                  project: campaign.project,
                }}
                charity={charity}
              />
            </div>
          </>
        )}

        {campaign.type === CampaignType.Team && (
          <>
            <hr />

            <div className="space-y-4">
              <div className="flex justify-between">
                <h2 className="flex items-center text-xl font-bold">
                  {t('frontend:team_members')}
                  {' '}
                  <span className="flex items-center justify-center h-6 ml-2 text-sm font-normal rounded-full min-w-[1.5rem] px-1 bg-slate-200 opacity-60">
                    {formatNumber(campaign.members.length)}
                  </span>
                </h2>
                {campaign.allow_join && !project.ended && (
                  <>
                    {user && potentialTeamMembers.length === 0 && (
                      <LinkButton
                        href={`${routes.create_campaign_page()}?project_id=${campaign.project.id}&team_id=${campaign.id}`}
                        size="sm"
                        variant="secondary"
                      >
                        <Icon className="mr-2">
                          <UserPlus />
                        </Icon>
                        {t('frontend:join')}
                      </LinkButton>
                    )}
                    {(!user || potentialTeamMembers.length > 0) && (
                      <>
                        <Button
                          onClick={() => setShowJoinTeamModal(true)}
                          size="sm"
                          variant="secondary"
                        >
                          <Icon className="mr-2">
                            <UserPlus />
                          </Icon>
                          {t('frontend:join')}
                        </Button>
                        <Modal isOpen={showJoinTeamModal} onClose={() => setShowJoinTeamModal(false)} size="sm">
                          <JoinTeamForm
                            charity={charity}
                            team={campaign}
                            showLogin={!user}
                            potentialTeamMembers={potentialTeamMembers}
                            onSuccess={() => setShowJoinTeamModal(false)}
                          />
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </div>
              {campaign.members.length === 0 && (
                <div className="text-slate-500">
                  {t('frontend:no_team_members_yet')}
                </div>
              )}

              {campaign.members.length > 0 && (
                <div className="space-y-2">
                  {campaign.members.map((member) => (
                    <CampaignCard
                      campaign={{
                        ...member,
                        project: campaign.project,
                      }}
                      charity={charity}
                      key={member.id}
                    />
                  ))}
                </div>
              )}
            </div>
          </>
        )}

        {event && (
          <>
            <hr />
            <div className="space-y-4">
              <h2 className="text-xl">
                {t('frontend:event')}
              </h2>
              <EventCard event={event} />
            </div>
          </>
        )}

        <hr />

        <div className="space-y-4">
          <div>
            <h2 className="flex items-center text-xl font-bold">
              {t('frontend:donations')}
              {' '}
              <span className="flex items-center justify-center h-6 ml-2 text-sm font-normal rounded-full min-w-[1.5rem] px-1 bg-slate-200 opacity-60">
                {formatNumber(campaign.donations_count)}
              </span>
            </h2>
            {donations.length === 0 && (
              <div className="mt-2 text-slate-500">
                {t('frontend:no_donations_yet')}
              </div>
            )}
          </div>

          {donations.length > 0 && (
            <div className="space-y-2">
              {donations.map((donation) => (
                <div className="p-4 rounded-lg sm:p-6 bg-slate-50" key={donation.id}>
                  <div className="space-y-2">
                    {donation.amount > 0 && (
                      <div className="text-lg font-bold">
                        {formatCurrency(donation.amount)}
                      </div>
                    )}

                    {donation.message && (
                      <div className="whitespace-pre-line text-slate-600">
                        {donation.message}
                      </div>
                    )}

                    <div className="flex items-end justify-between">
                      <span className="font-medium">
                        {donation.published_name || t('frontend:anonymous')}
                      </span>
                      <small className="text-slate-400 whitespace-nowrap">
                        {formatRelativeDate(donation.created_at)}
                      </small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {hasMoreDonations && (
            <Button
              onClick={() => loadMoreDonations()}
              variant="secondary"
              className="block w-full !rounded-lg"
              loading={loading}
            >
              {t('frontend:show_more')}
            </Button>
          )}
        </div>
      </Container>

      <Footer charity={charity} />
    </>
  );
}

CampaignPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
