import { Calendar, Globe, MapPin } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Event } from '../../Shared/types';
import DateRange from '../../Shared/UI/DateRange';
import { Icon } from '../../Shared/UI/Icon';

export interface EventHeaderProps {
  event: Event & {
    funds_raised: number;
    campaigns_count: number;
    charities_count: number;
  };
}

export default function EventHeader({
  event,
}: EventHeaderProps) {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();

  return (
    <>
      <div className="flex items-center space-x-4">
        {event.logo_url && (
          <div className="shrink-0">
            <div className="w-[100px] h-[100px] shadow bg-white border rounded-full flex items-center justify-center">
              <img src={event.logo_url} className="block max-h-[84px] max-w-[84px] rounded-full" />
            </div>
          </div>
        )}
        <div>
          <h2 className="text-lg">
            {event.title}
          </h2>
          <ul className="font-medium text-slate-500">
            <li className="flex gap-2">
              <Icon className="mt-1">
                <Calendar />
              </Icon>
              <span>
                <DateRange start={event.start_date} end={event.end_date} />
              </span>
            </li>
            {event.location && (event.location.id || event.location.description) && (
              <li className="flex gap-2">
                <Icon className="mt-1">
                  <MapPin />
                </Icon>
                <span>
                  {event.location.id && (
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location.description)}&query_place_id=${encodeURIComponent(event.location.id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-slate-500"
                    >
                      {event.location.description.split(',')[0]}
                    </a>
                  )}
                  {!event.location.id && event.location.description}
                </span>
              </li>
            )}
            {event.website && (
              <li className="flex gap-2">
                <Icon className="mt-1">
                  <Globe />
                </Icon>
                <a
                  href={event.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-slate-500"
                >
                  {getUrlWithoutPrefix(event.website)}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <hr />
      <div className="grid grid-flow-col gap-2 auto-cols-auto">
        <div>
          <div className="font-bold">
            {formatCurrency(event.funds_raised, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </div>
          <span className="text-sm font-medium text-slate-600">
            {t('frontend:raised')}
          </span>
        </div>
        <div>
          <div className="font-bold">
            {formatNumber(event.campaigns_count)}
          </div>
          <span className="text-sm font-medium text-slate-600">
            {t('frontend:campaigns', { count: event.campaigns_count })}
          </span>
        </div>
        <div>
          <div className="font-bold">
            {formatNumber(event.charities_count)}
          </div>
          <span className="text-sm font-medium text-slate-600">
            {t('frontend:charities', { count: event.charities_count })}
          </span>
        </div>
      </div>
    </>
  );
}

/**
 * Strips http(s)://, www., trailing slash, and query parameters from a URL.
 */
const getUrlWithoutPrefix = (url: string) => (
  url.replace(/^https?:\/\//i, '').replace(/^www\./i, '').split('?')[0].replace(/\/$/, '')
);
