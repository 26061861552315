
import classNames from 'classnames';
import { useState } from 'react';
import { Check, ChevronLeft, Copy, Download, Link } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { assetUrl } from '../../Shared/helpers';
import { Campaign, Charity, Event, Project } from '../../Shared/types';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { Icon } from '../../Shared/UI/Icon';
import QrCode from '../../Shared/UI/Icons/QrCode';
import { Input } from '../../Shared/UI/Input';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import { useCopy } from '../../Shared/useCopy';
import { Button } from '../UI/Button';

export default function ShareOptions({
  campaign,
  charity,
}: {
  campaign: Campaign & {
    project: Project & {
      event: Event | null;
    };
  };
  charity: Charity;
}) {
  const { t } = useTranslation();

  const url = encodeURIComponent(campaign.url);
  const shareMessage = encodeURIComponent(t(campaign.project.event ? 'frontend:share_message_event' : 'frontend:share_message', {
    charity: charity.title,
    event: campaign.project.event?.title,
  }) + '\n\n' + campaign.url);
  const [copyUrl, copiedUrl] = useCopy(campaign.url);

  const [showQr, setShowQr] = useState(false);

  const downloadQrCode = async () => {
    const svg = document.getElementById('qr_code');

    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');

        const downloadLink = document.createElement('a');
        downloadLink.download = 'qrcode';
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };

      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {campaign.thumbnail_url && (
          <div className="rounded relative w-[5rem] h-[3.333rem]">
            <div className="absolute inset-0 z-10 shadow-inner" />
            <img src={campaign.thumbnail_url} alt={campaign.title} className="object-cover w-full h-full rounded" />
          </div>
        )}
        <div className="leading-snug">
          <h1 className={classNames('text-lg font-medium text-slate-600', campaign.thumbnail_url && 'mt-0.5')}>
            <Trans i18nKey="frontend:share_title" values={{ campaign: campaign.title }}>
              <strong className="text-slate-900" />
            </Trans>
          </h1>
          <span className="text-slate-500">
            {campaign.campaigner !== campaign.title && `${campaign.campaigner}  · `}
            {charity.title}
          </span>
        </div>
      </div>

      {!showQr && (
        <FadeIn className="space-y-6">
          <div className="grid grid-cols-2 gap-2">
            <a
              href={`https://api.whatsapp.com/send?text=${shareMessage}`}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-3 font-medium text-center border rounded hover:bg-slate-50 text-slate-800 hover:text-slate-900"
            >
              <img
                src={assetUrl('images/logo-whatsapp.png')}
                alt={t('frontend:whatsapp')}
                className="block mx-auto mb-1 w-7 h-7 mt-0.5"
              />
              {t('frontend:whatsapp')}
            </a>
            <a
              href={`https://twitter.com/intent/tweet?text=${shareMessage}`}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-3 font-medium text-center border rounded hover:bg-slate-50 text-slate-800 hover:text-slate-900"
            >
              <img
                src={assetUrl('images/logo-x.png')}
                alt={t('frontend:x')}
                className="block mx-auto mb-1 w-7 h-7 mt-0.5"
              />
              {t('frontend:x')}
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-3 font-medium text-center border rounded hover:bg-slate-50 text-slate-800 hover:text-slate-900"
            >
              <img
                src={assetUrl('images/logo-facebook.png')}
                alt={t('frontend:facebook')}
                className="block mx-auto mb-1 w-7 h-7 mt-0.5"
              />
              {t('frontend:facebook')}
            </a>
            <a
              href={`fb-messenger://share/?link=${url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-3 font-medium text-center border rounded hover:bg-slate-50 text-slate-800 hover:text-slate-900"
            >
              <img
                src={assetUrl('images/logo-messenger.png')}
                alt={t('frontend:messenger')}
                className="block mx-auto mb-1 w-7 h-7 mt-0.5"
              />
              {t('frontend:messenger')}
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-3 font-medium text-center border rounded hover:bg-slate-50 text-slate-800 hover:text-slate-900"
            >
              <img
                src={assetUrl('images/logo-linkedin.png')}
                alt={t('frontend:linkedin')}
                className="block mx-auto mb-1 w-7 h-7 mt-0.5"
              />
              {t('frontend:linkedin')}
            </a>
            <a
              onClick={() => setShowQr(true)}
              role="button"
              className="block p-3 font-medium text-center border rounded hover:bg-slate-50 text-slate-800 hover:text-slate-900"
            >
              <Icon className="!block mx-auto !mb-0.5 !mt-0 !w-8 !h-8">
                <QrCode />
              </Icon>
              {t('frontend:qr_code')}
            </a>
          </div>
          <div className="flex space-x-2">
            <InputWrapper className="w-full">
              <label htmlFor="campaign_url_field">
                <Icon className="text-slate-600">
                  <Link />
                </Icon>
              </label>
              <Input
                type="text"
                value={campaign.url}
                readOnly
                onFocus={(event) => event.target.select()}
                id="campaign_url_field"
                className="pl-9"
              />
            </InputWrapper>
            <Button
              onClick={() => copyUrl()}
              variant="secondary"
              className={classNames(copiedUrl && '!text-slate-400')}
              title={t('frontend:copy')}
            >
              <Icon className="mr-2">
                {copiedUrl ? <Check /> : <Copy />}
              </Icon>
              {t('frontend:copy')}
            </Button>
          </div>
        </FadeIn>
      )}

      {showQr && (
        <FadeIn className="space-y-6 text-center ">
          <hr />
          <div className="w-[200px] mx-auto mb-2">
            <QRCode value={campaign.url} size={1000} id="qr_code" className="w-[200px] h-[200px]" />
          </div>
          <div className="text-sm text-slate-500">
            {t('frontend:campaign_qr_description')}
          </div>
          <div className="space-x-3 text-center">
            <Button onClick={() => setShowQr(false)} variant="secondary">
              <Icon className="mr-2">
                <ChevronLeft />
              </Icon>
              {t('frontend:back')}
            </Button>
            <Button onClick={() => downloadQrCode()} variant="secondary">
              <Icon className="mr-2">
                <Download />
              </Icon>
              {t('frontend:download')}
            </Button>
          </div>
        </FadeIn>
      )}
    </div>
  );
}
