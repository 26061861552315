import { Link } from '@inertiajs/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneNumber from '../../Shared/Phone/PhoneNumber';
import { Charity } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import { NavBar } from '../UI/NavBar';

interface HelpPageProps extends SharedProps {
  charity: Charity;
}

export default function HelpPage({
  auth: { user },
  charity,
}: HelpPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  useCharityStyle(charity);

  return (
    <>
      <NavBar user={user} charity={charity} />

      <Container>
        <div>
          <h1 className="mb-1 text-2xl">
            {t('frontend:need_help')}
          </h1>
          <p>
            {t('frontend:need_help_description', { charity: charity.title })}
          </p>
        </div>

        <Card shadow={false} className="!bg-slate-100">
          <ul className="space-y-1">
            {charity.email && (
              <li>
                <span className="font-medium">{t('frontend:email')}:</span>
                {' '}
                <a href={`mailto:${charity.email}`}>{charity.email}</a>
              </li>
            )}
            {charity.phone && (
              <li>
                <span className="font-medium">{t('frontend:phone')}:</span>
                {' '}
                <a href={`tel:${charity.phone}`}>
                  <PhoneNumber phoneNumber={charity.phone} />
                </a>
              </li>
            )}
            {!charity.email && !charity.phone && (
              <li>
                {t('frontend:no_contact_details_set', { charity: charity.title })}
              </li>
            )}
          </ul>
        </Card>

        <div>
          <h2 className="mb-1 text-xl">
            {t('frontend:frequently_asked_questions')}
          </h2>
          <p>
            {t('frontend:frequently_asked_questions_description')}
            {' '}
            <Link href={routes.content_faq_page()}>
              {t('frontend:view_frequently_asked_questions')}
            </Link>
          </p>
        </div>
      </Container>

      <Footer charity={charity} />
    </>
  );
}

HelpPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
