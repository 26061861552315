import { ReactNode } from 'react';

import DefaultLayout from './Layout';

export default function CharityLayout({
  children,
}: {
  children?: ReactNode;
}) {
  return (
    <DefaultLayout>
      {children}
    </DefaultLayout>
  );
}
