import '../css/app.css';

import { createInertiaApp, router } from '@inertiajs/react';
import axios from 'axios';
import i18n from 'i18next';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';

import { SharedProps } from './Frontend/types';
import { disableBackForwardCaching } from './Shared/inertia';
import RollBarProvider from './Shared/Rollbar/RollbarProvider';

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);

  createInertiaApp<SharedProps>({
    resolve: (name) => {
      const pages = import.meta.glob('./Frontend/**[!__]/*Page.tsx', { eager: true });
      return pages[`./${name}.tsx`];
    },
    title: (title) => title || 'Supporta',
    setup({ App, props }) {
      const { locale, translations, auth: { sessionId } } = props.initialPage.props;

      axios.interceptors.request.use(function (config) {
        // Send the same session ID on every request, so that the back-end can use
        // sessions to flash data even when cookies are not available in the browser.
        config.headers['X-Supporta-Session'] = sessionId;

        // Send the current location on every request, so that the back-end can return
        // to the current page on validation errors.
        config.headers['X-Supporta-Referrer'] = window.location.href;

        return config;
      });

      i18n
        .use(initReactI18next)
        .init({
          resources: {
            [locale as string]: translations,
          },
          lng: locale as string,
          interpolation: {
            escapeValue: false,
          },
        });

      disableBackForwardCaching(router);

      root.render(
        <RollBarProvider>
          <App {...props} />
        </RollBarProvider>
      );
    },
  });
}
