import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Charity, DonationForm } from '../../Shared/types';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { SharedProps } from '../types';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import { NavBar } from '../UI/NavBar';
import { Subscription } from './helpers';
import { PersonalDetailsCard } from './PersonalDetailsCard';
import { RecurringDonationCard } from './RecurringDonationCard';

export interface SubscriptionPageProps extends SharedProps {
  subscription: Subscription;
  donationForm: DonationForm;
  charity: Charity;
}

export default function SubscriptionPage({
  subscription,
  donationForm,
  charity,
  auth: { user },
}: SubscriptionPageProps) {
  const { t } = useTranslation();

  useCharityStyle(charity);

  return (
    <>
      <NavBar charity={charity} user={user} showCampaignButton={false} />

      <Container className="relative z-1">
        <h1 className="font-normal text-base text-slate-500">
          <SupportaSymbol className="inline-block h-3 mr-2 -mt-0.5" />
          {t('frontend:donor_dashboard')}
        </h1>

        <hr />

        <div>
          <h2 className="text-2xl mb-1">
            {t('frontend:your_donation_to_charity', { charity: charity.title })}
          </h2>
          {t('frontend:your_donation_to_charity_description', { name: subscription.first_name })}
        </div>

        <RecurringDonationCard subscription={subscription} charity={charity} />

        <hr />

        <div>
          <h1 className="text-xl mb-1">
            {t('frontend:your_details')}
          </h1>
          {t('frontend:your_personal_details_description')}
        </div>

        <PersonalDetailsCard subscription={subscription} donationForm={donationForm} />
      </Container>

      <Footer charity={charity} />
    </>
  );
}

SubscriptionPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;

