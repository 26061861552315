import { Link, usePage } from '@inertiajs/react';
import { useViewportSize } from '@mantine/hooks';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Menu, User as UserIcon, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { EventType } from '../../Shared/eventTracking';
import { assetUrl } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { Charity, User } from '../../Shared/types';
import { AButton, Button, LinkButton } from '../../Shared/UI/Button';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { Icon } from '../../Shared/UI/Icon';
import { useRouter } from '../router';
import { NavItem, PageProps } from '../types';
import Container from './Container';
import { EmbedContext } from './EmbedProvider';

interface NavBarProps {
  navItems?: NavItem[];
  user?: User | null;
  charity?: Charity | null;
  showCampaignButton?: boolean;
  campaignButtonVariant?: 'primary' | 'secondary';
  showLoginButton?: boolean;
}

export const NavBar = ({
  navItems,
  user,
  charity,
  showCampaignButton = true,
  showLoginButton = true,
}: NavBarProps) => {
  const { t } = useTranslation();
  const { isEmbedded } = useContext(EmbedContext);
  const { routes } = useRouter();
  const { locale } = useLocale();

  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { url } = usePage<PageProps>();

  const loginLink = `/login?redirect_url=${encodeURIComponent(window.location.href)}&locale=${locale}`;

  const closeEmbed = () => {
    window.parent.postMessage({
      type: EventType.WidgetClosed,
    }, '*');
  };

  const [showContentNav, setShowContentNav] = useState(false);

  const getLinkClassNames = (active = false) => classNames(
    'font-medium text-base text-slate-500 hover:text-slate-800 p-2 block',
    active ? 'text-slate-800' : 'text-slate-500',
  );

  const getMobileLinkClassNames = (active = false) => classNames(
    'font-medium text-xl text-slate-500 hover:text-slate-800 p-2 block',
    active ? 'text-slate-800' : 'text-slate-500',
  );

  const { width } = useViewportSize();
  const isMobile = width > 0 && width < 1024;
  const [showMobileNav, setShowMobileNav] = useState(false);

  useEffect(() => {
    if (showMobileNav) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showMobileNav]);

  const [showDomainMessage, setShowDomainMessage] = useState(!isEmbedded && localStorage.getItem('hideDomainMessage') !== 'true');

  const hideDomainMessage = () => {
    setShowDomainMessage(false);
    localStorage.setItem('hideDomainMessage', 'true');
  };

  return (
    <div className="relative z-[100]">
      {showDomainMessage && (
        <div className="bg-amber-200 h-12 text-center font-semibold text-slate-800 flex items-center justify-between sm:justify-center px-6">
          <div className="text-sm">
            {t('frontend:homepage.supporta_domain_message')}
          </div>
          <a onClick={() => hideDomainMessage()} className="text-slate-800 hover:text-slate-900 inline-block p-1" role="button">
            <Icon className="ml-2">
              <X />
            </Icon>
          </a>
        </div>
      )}
      <div className="min-h-14">
        <div
          className={classNames(!isEmbedded
            ? classNames((scrolled > 48 || (scrolled > 0 && !showDomainMessage)) && 'bg-white/90 backdrop-blur-sm border-b border-black/5 fixed top-0 left-0 right-0', 'transition-colors')
            : 'shadow-sm relative z-10'
          )}
        >
          <Container size="lg" className="!py-3">
            <div>
              <div
                className={classNames(
                  'grid items-center',
                  isMobile || isEmbedded ? 'grid-cols-2' : 'grid-cols-[1fr_fit-content(550px)_1fr]'
                )}
              >
                <div className="flex items-center">
                  <a
                    href={(!charity || charity.public || !charity.website) ? routes.home_page(charity?.slug) : charity.website}
                    className={classNames(isEmbedded && 'pointer-events-none', 'flex items-center h-8')}
                    target={charity && !charity.public && charity.website ? '_blank' : undefined}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={charity?.logo_url || assetUrl('images/logo.svg')}
                      className={classNames('transition-all', charity?.logo_url ? scrolled ? 'h-6' : 'h-8' : scrolled ? 'h-4' : 'h-5')}
                      alt={charity?.logo_url ? charity.title : 'Supporta'}
                    />
                  </a>
                </div>

                {!isMobile && !isEmbedded && (
                  <div className="flex justify-center items-center space-x-4 -my-2">
                    {navItems && (
                      <>
                        <Link
                          href={routes.home_page()}
                          className={getLinkClassNames(url === routes.home_page())}
                        >
                          {t('frontend:homepage.campaigns')}
                        </Link>
                        <Link
                          href={routes.charities_page()}
                          className={getLinkClassNames(url === routes.charities_page())}
                        >
                          {t('frontend:homepage.charities')}
                        </Link>
                        <Link
                          href={routes.events_page()}
                          className={getLinkClassNames(url === routes.events_page())}
                        >
                          {t('frontend:homepage.events')}
                        </Link>
                        {navItems.length > 0 && (
                          <div
                            className="relative pr-2"
                            onMouseEnter={() => setShowContentNav(true)}
                            onMouseLeave={() => setShowContentNav(false)}
                          >
                            <a
                              role="button"
                              onClick={() => setShowContentNav((show) => !show)}
                              className={getLinkClassNames()}
                            >
                              {t('frontend:homepage.about')}
                              <Icon className="ml-1">
                                {showContentNav ? <ChevronUp /> : <ChevronDown />}
                              </Icon>
                            </a>
                            <FadeIn
                              when={showContentNav}
                              onMouseEnter={() => setShowContentNav(true)}
                              delay={false}
                              render={() => (
                                <div className="bg-white rounded-lg shadow-[0_1px_2px_0_rgb(0_0_0_/_0.1),0_5px_15px_-3px_rgb(0_0_0_/_0.1),_0_0_4px_-2px_rgb(0_0_0_/_0.05)] absolute -left-2 top-10 p-2 whitespace-nowrap">
                                  {navItems.map((navItem) => (
                                    <Link
                                      href={navItem.url}
                                      className={classNames(
                                        'text-slate-500 hover:text-slate-800 p-2 rounded block hover:bg-slate-100',
                                        navItem.active ? 'text-slate-800' : 'text-slate-500',
                                      )}
                                      key={navItem.id}
                                    >
                                      {navItem.title}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                <div className="flex justify-end items-center space-x-3">
                  {(!isMobile || !showCampaignButton) && showLoginButton && user && (
                    <AButton
                      href={routes.dashoard_page()}
                      variant="tertiary"
                      size="sm"
                      className="!text-slate-800 !px-1"
                    >
                      <Icon className="mr-1">
                        <UserIcon />
                      </Icon>
                      {user.first_name.substring(0, 15)}
                      {user.first_name.length > 15 && '…'}
                    </AButton>
                  )}

                  {(!isMobile || !showCampaignButton) && showLoginButton && !user && (
                    <AButton
                      href={loginLink}
                      variant="tertiary"
                      size="sm"
                      className="!text-slate-800 !px-1"
                      target={isEmbedded ? '_blank' : undefined}
                      rel={isEmbedded ? 'noreferrer noopener' : undefined}
                    >
                      <Icon className="mr-2">
                        <UserIcon />
                      </Icon>
                      {t('frontend:log_in')}
                    </AButton>
                  )}

                  {!isEmbedded && showCampaignButton && (!charity || charity?.public) && (
                    <LinkButton
                      href={routes.select_charity_page(charity?.slug)}
                      variant={!charity && scrolled ? 'primary' : 'secondary'}
                      size="sm"
                    >
                      {t('frontend:start_campaign')}
                    </LinkButton>
                  )}

                  {isMobile && !isEmbedded && showCampaignButton && (
                    <Button
                      onClick={() => setShowMobileNav(true)}
                      iconButton
                      variant="tertiary"
                      size="sm"
                      className="!text-2xl text-slate-400 hover:bg-slate-100"
                      aria-label={t('frontend:close')}
                    >
                      <Icon>
                        <Menu strokeWidth={2} />
                      </Icon>
                    </Button>
                  )}

                  {isEmbedded && (
                    <Button
                      onClick={() => closeEmbed()}
                      iconButton
                      variant="tertiary"
                      size="sm"
                      className="!text-2xl text-slate-400 hover:bg-slate-100"
                      aria-label={t('frontend:close')}
                    >
                      <Icon>
                        <X strokeWidth={2} />
                      </Icon>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>

        {isMobile && !isEmbedded && (
          <>
            {showMobileNav && <FadeIn className="fixed inset-0 backdrop-blur-lg bg-black/10 z-[200]" />}

            <div
              className={classNames(
                'fixed right-0 top-0 bottom-0 overflow-hidden bg-white transition-all z-[210]',
                showMobileNav ? 'w-[360px] shadow-xl' : 'w-0 shadow-none'
              )}
            >
              <div className="overflow-y-auto w-[360px] max-h-full">
                <div className="h-14 flex items-center justify-between pl-8 pr-6 sm:pr-8">
                  <a
                    href={routes.home_page(charity?.public ? charity?.slug : null)}
                    className="flex items-center h-8"
                  >
                    <img
                      src={charity?.logo_url || assetUrl('images/logo.svg')}
                      className="mt-[2px] h-4"
                      alt="Supporta"
                    />
                  </a>

                  <Button
                    onClick={() => setShowMobileNav(false)}
                    iconButton
                    variant="tertiary"
                    size="sm"
                    className="!text-2xl text-slate-400 hover:bg-slate-100"
                    aria-label={t('frontend:close')}
                  >
                    <Icon>
                      <X strokeWidth={2} />
                    </Icon>
                  </Button>
                </div>
                <div className="px-6 sm:px-8 py-4">
                  {navItems && (
                    <div className="mb-4">
                      <Link
                        href={routes.home_page()}
                        className={getMobileLinkClassNames(url === routes.home_page())}
                      >
                        {t('frontend:homepage.campaigns')}
                      </Link>
                      <Link
                        href={routes.charities_page()}
                        className={getMobileLinkClassNames(url === routes.charities_page())}
                      >
                        {t('frontend:homepage.charities')}
                      </Link>
                      <Link
                        href={routes.events_page()}
                        className={getMobileLinkClassNames(url === routes.events_page())}
                      >
                        {t('frontend:homepage.events')}
                      </Link>
                      {navItems.length > 0 && (
                        <>
                          <a
                            role="button"
                            onClick={() => setShowContentNav((show) => !show)}
                            className={getMobileLinkClassNames()}
                          >
                            {t('frontend:homepage.about')}
                            <Icon className="ml-1">
                              {showContentNav ? <ChevronUp /> : <ChevronDown />}
                            </Icon>
                          </a>
                          <FadeIn
                            when={showContentNav}
                            delay={false}
                            render={() => (
                              <div>
                                {navItems.map((navItem) => (
                                  <Link
                                    href={navItem.url}
                                    className={classNames(
                                      'text-slate-500 hover:text-slate-800 p-2 rounded block hover:bg-slate-100',
                                      navItem.active ? 'text-slate-800' : 'text-slate-500',
                                    )}
                                    key={navItem.id}
                                  >
                                    {navItem.title}
                                  </Link>
                                ))}
                              </div>
                            )}
                          />
                        </>
                      )}
                    </div>
                  )}
                  <div className="space-y-4">
                    {user && (
                      <AButton
                        href={routes.dashoard_page()}
                        variant="secondary"
                        className="block w-full"
                      >
                        <Icon className="mr-1">
                          <UserIcon />
                        </Icon>
                        {user.first_name.substring(0, 15)}
                        {user.first_name.length > 15 && '…'}
                      </AButton>
                    )}

                    {!user && (
                      <AButton
                        href={loginLink}
                        variant="secondary"
                        className="block w-full"
                        target={isEmbedded ? '_blank' : undefined}
                        rel={isEmbedded ? 'noreferrer noopener' : undefined}
                      >
                        <Icon className="mr-2">
                          <UserIcon />
                        </Icon>
                        {t('frontend:log_in')}
                      </AButton>
                    )}

                    <LinkButton
                      href={routes.select_charity_page(charity?.slug)}
                      variant="primary"
                      className="block w-full"
                    >
                      {t('frontend:start_campaign')}
                    </LinkButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
