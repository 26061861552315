import SupportaSymbol from '../../Shared/UI/SupportaSymbol';

export const Background = () => (
  <div className="absolute -top-[25px] md:-top-[100px] left-0 right-0 z-0 bg-gradient-to-b from-blue-50 overflow-hidden">
    <div className="w-full min-h-full bg-gradient-to-t from-white via-white z-10 absolute inset-0"></div>
    <div className="w-1/2 min-h-full bg-gradient-to-tl from-white/50 z-10 absolute left-0 botom-0 top-0"></div>
    <div className="w-1/2 min-h-full bg-gradient-to-tr from-white/50 z-10 absolute right-0 botom-0 top-0"></div>
    <SupportaSymbol className="w-full max-w-[800px] mx-auto text-blue-300 opacity-25 relative z-0" />
  </div>
);
