import { useViewportSize } from '@mantine/hooks';
import classNames from 'classnames';
import { Fragment, ReactNode, useState } from 'react';
import { ArrowRight, ArrowRightCircle, Check, Heart, Minus, Plus, Zap } from 'react-feather';

import { LinkButton } from '../../Shared/UI/Button';
import { HTML } from '../../Shared/UI/HTML';
import { Icon } from '../../Shared/UI/Icon';
import { SharedProps } from '../types';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import { Background } from './Background';

const backgroundClassNames = {
  white: 'bg-white',
  slate: 'bg-slate-50',
};

const icons = {
  check: () => <Check />,
  arrow_right: () => <ArrowRight />,
  arrow_right_circle: () => <ArrowRightCircle />,
  heart: () => <Heart />,
  zap: () => <Zap />,
};

interface TextContentType {
  type: 'text';
  text: string;
}

interface HtmlContentType {
  type: 'html';
  desktop: {
    value: string | null;
  };
  mobile: {
    value: string | null;
  };
}

interface CtasContentType {
  type: 'ctas';
  align: { value: 'left' | 'center' | 'right'; };
  ctas: {
    id: string;
    cta_text: string;
    cta_url: string;
    variant: { value: 'primary' | 'secondary'; };
  }[];
}

interface FaqsContentType {
  type: 'faqs';
  faqs: {
    id: string;
    title: string;
    content: string | null;
  }[];
}

interface UspsContentType {
  type: 'usps';
  align: { value: 'left' | 'center' | 'right'; };
  usps: {
    id: string;
    icon: { value: keyof typeof icons; };
    content: string;
  }[];
}

interface ListContentType {
  type: 'list';
  list_items: {
    id: string;
    content: string;
  }[];
}

type ContentType = TextContentType | HtmlContentType | CtasContentType | UspsContentType | FaqsContentType | ListContentType;

interface Section {
  id: string;
  background: { value: 'white' | 'slate'; };
  container_width: { value: 'sm' | 'md' | 'lg'; };
  content: ContentType[];
}

interface Page {
  content: Section[];
}

export interface ContentPageProps extends SharedProps {
  page: Page;
}

export default function ContentPage({
  page,
  contentNav,
  auth: {
    user,
  },
}: ContentPageProps) {
  console.log(page);
  return (
    <>
      <NavBar navItems={contentNav} user={user} />

      <Background />

      <div className="relative z-2">
        <div className="divide-y divide-slate-200 relative z-2">
          {page.content.map((section) => (
            <div className={classNames(backgroundClassNames[section.background.value])} key={section.id}>
              <Container
                size={section.container_width.value}
                spacing="lg"
              >
                <div className="space-y-8">
                  {section.content.map((content, index) => (
                    <Fragment key={index}>
                      {content.type === 'text' && (
                        <TextContent content={content} />
                      )}
                      {content.type === 'html' && (
                        <HtmlContent content={content} />
                      )}
                      {content.type === 'ctas' && (
                        <CtasContent content={content} />
                      )}
                      {content.type === 'faqs' && (
                        <FaqsContent content={content} />
                      )}
                      {content.type === 'usps' && (
                        <UspsContent content={content} />
                      )}
                      {content.type === 'list' && (
                        <ListContent content={content} />
                      )}
                    </Fragment>
                  ))}
                </div>
              </Container>
            </div>
          ))}
        </div>

        <Footer links={contentNav} />
      </div>
    </>
  );
}

interface TextContentProps {
  content: TextContentType;
}

const TextContent = ({ content }: TextContentProps) => (
  <HTML
    value={content.text.replace(/text-align: center;/g, 'text-align: center; text-wrap: balance;')}
    className="[&>p:not(:last-child)]:mb-6 [&>ul:not(:last-child)]:mb-6 [&>ol:not(:last-child)]:mb-6 [&>blockquote:not(:last-child)]:mb-6 [&_h1]:text-3xl [&_h2]:text-xl [&_h3]:text-lg [&_h1]:mb-4 [&_h2]:mb-2 [&_h3]:mb-1 [&_hr]:my-8 [&_blockquote]:text-2xl [&_blockquote]:font-light [&_blockquote]:text-balance [&_blockquote]:text-slate-600 [&_table]:w-full [&_table]:text-slate-500 [&_table]:text-base [&_img]:block [&_img]:w-full [&_img]:rounded-xl"
  />
);

interface HtmlContentProps {
  content: HtmlContentType;
}

const HtmlContent = ({ content }: HtmlContentProps) => {
  const { width } = useViewportSize();

  if (width >= 768) {
    return <HTML value={content.desktop.value || content.mobile.value || ''} />;
  }

  return <HTML value={content.mobile.value || content.desktop.value || ''} />;
};

interface CtasContentProps {
  content: CtasContentType;
}

interface CtasContentProps {
  content: CtasContentType;
}

const CtasContent = ({ content }: CtasContentProps) => (
  <div
    className={classNames(
      'space-x-3',
      content.align.value === 'center' && 'text-center',
      content.align.value === 'right' && 'text-right',
    )}
  >
    {content.ctas.map((cta) => (
      <LinkButton href={cta.cta_url} variant={cta.variant.value} key={cta.id}>
        {cta.cta_text}
      </LinkButton>
    ))}
  </div>
);

interface FaqsContentProps {
  content: FaqsContentType;
}

const FaqsContent = ({ content }: FaqsContentProps) => (
  <div>
    {content.faqs.map((faq) => (
      <FaqItem faq={faq} key={faq.id} />
    ))}
  </div>
);

interface FaqItemProps {
  faq: FaqsContentType['faqs'][0];
}

const FaqItem = ({ faq }: FaqItemProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="border-b pt-4" key={faq.id}>
      <h3 className="text-lg font-medium">
        <a
          onClick={() => setExpanded((expanded) => !expanded)}
          role="button"
          className="py-4 text-slate-800 hover:text-slate-800 flex items-center justify-between [&:hover_svg]:text-slate-800"
        >
          <span className="mr-3">
            {faq.title}
          </span>
          <Icon className="text-emerald-500 mr-2">
            {expanded ? <Minus /> : <Plus />}
          </Icon>
        </a>
      </h3>
      <div
        className={classNames(
          'overflow-hidden transition-opacity mb-4',
          expanded ? 'h-auto opacity-100' : 'h-0 opacity-0'
        )}
      >
        <div className="pb-4">
          <TextContent content={{ type: 'text', text: faq.content || '' }} />
        </div>
      </div>
    </div>
  );
};

interface UspsContentProps {
  content: UspsContentType;
}

const UspsContent = ({ content }: UspsContentProps) => (
  <ul
    className={classNames(
      'space-y-2 sm:space-y-0 sm:flex sm:space-x-4',
      content.align.value === 'center' && 'justify-center',
      content.align.value === 'right' && 'justify-end',
    )}
  >
    {content.usps.map((usp) => (
      <li className="flex items-center" key={usp.id}>
        {usp.icon.value && (
          <Icon className="mr-2 text-emerald-500">
            {icons[usp.icon.value]()}
          </Icon>
        )}
        <HTML value={usp.content} />
      </li>
    ))}
  </ul>
);

interface ListContentProps {
  content: ListContentType;
}

const ListContent = ({ content }: ListContentProps) => (
  <div className="space-y-6">
    {content.list_items.map((listItem, index) => (
      <div className="bg-blue-100 p-6 rounded-xl flex space-x-4 sm:space-x-6" key={listItem.id}>
        <div className="bg-blue-500 w-10 h-10 sm:w-12 sm:h-12 mt-0.5 rounded-full flex items-center justify-center font-light text-white text-xl shrink-0">
          {index + 1}
        </div>
        <TextContent content={{ type: 'text', text: listItem.content || '' }} />
      </div>
    ))}
  </div>
);

ContentPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
