
import { ChevronRight } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { useMutation } from '../../Shared/api';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { Campaign, Charity, Project } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { useRouter } from '../router';
import CampaignCard from './CampaignCard';

export default function JoinTeamForm({
  charity,
  team,
  showLogin,
  potentialTeamMembers,
  onSuccess,
}: {
  charity: Charity;
  team: Campaign & {
    project: Project;
  };
  showLogin: boolean;
  potentialTeamMembers: (Campaign & {
    project: Project;
  })[];
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { locale } = useLocale();
  const notifier = useNotifier();

  const [joinTeam] = useMutation(routes.join_team(team.id), {
    onSuccess: () => {
      notifier.notify(t('frontend:team_joined'));
      onSuccess?.();
    },
  });

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h3 className="py-1">
          {showLogin ? t('frontend:join_team') : t('frontend:select_team_member')}
        </h3>
        <p className="text-slate-700">
          <Trans i18nKey="frontend:join_team_description" values={{ team: team.title }}>
            <span className="font-medium" />
          </Trans>
        </p>
      </div>

      <div className="space-y-2">
        {potentialTeamMembers.map((member) => (
          <CampaignCard
            campaign={member}
            charity={charity}
            onClick={() => joinTeam({ member_id: member.id })}
            key={member.id}
          />
        ))}

        {showLogin && (
          <Card
            link={{
              href: `${routes.login_page()}?charity_id=${charity.id}&locale=${locale}&redirect_url=${encodeURIComponent(`${window.location.href}?join_team=1`)}`,
              ariaLabel: t('frontend:log_in'),
              reload: true,
            }}
            border
            spacing="xs"
            className="font-medium flex justify-between items-center"
          >
            <span>
              {t('frontend:log_in')}
            </span>
            <Icon className="ml-1">
              <ChevronRight />
            </Icon>
          </Card>
        )}

        <Card
          link={{
            href: `${routes.create_campaign_page()}?project_id=${team.project.id}&team_id=${team.id}`,
            ariaLabel: t('frontend:create_new_campaign'),
          }}
          border
          spacing="xs"
          className="font-medium flex justify-between items-center"
        >
          <span>
            {t('frontend:create_new_campaign')}
          </span>
          <Icon className="ml-1">
            <ChevronRight />
          </Icon>
        </Card>
      </div>
    </div>
  );
}
