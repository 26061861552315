import { ReactNode, useEffect } from 'react';
import { Check } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { dispatchNewDonationEvent } from '../../Shared/eventTracking';
import { Charity, Donation, DonationForm, PaymentStatus, Subscription } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { HTML } from '../../Shared/UI/HTML';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useGaEvents } from '../../Shared/useGaEvents';
import { SharedProps } from '../types';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import { NavBar } from '../UI/NavBar';
import PageOfflineWarning from '../UI/PageOfflineWarning';

interface DonationFormPageProps extends SharedProps{
  donationForm: DonationForm;
  donation?: Donation;
  subscription?: Subscription;
  charity: Charity;
}

export default function DonationFormPage({
  donationForm,
  donation,
  subscription,
  charity,
}: DonationFormPageProps) {
  const { t } = useTranslation();

  useCharityStyle(charity);

  useGaEvents(charity.google_analytics_id);
  useGaEvents(window.Supporta?.googleAnalyticsId);

  // Dispatch event when a donation is accepted.
  useEffect(() => {
    if (donation?.active || donation?.payment_status === PaymentStatus.PENDING) {
      dispatchNewDonationEvent({
        charity,
        donation_form: donationForm,
        donation: {
          id: donation.id,
          amount: donation.amount,
          frequency: 'oneoff',
        },
      });
    }
  }, [donation]);

  // Dispatch event when a subscription is accepted.
  useEffect(() => {
    if (subscription) {
      dispatchNewDonationEvent({
        charity,
        donation_form: donationForm,
        donation: {
          id: subscription.id,
          amount: subscription.amount,
          frequency: subscription.frequency,
        },
      });
    }
  }, [subscription]);

  return (
    <>
      <NavBar charity={charity} showLoginButton={false} showCampaignButton={false} />

      <Container>
        {!donationForm.enabled && (
          <PageOfflineWarning />
        )}

        {(subscription || donation?.active) && (
          <Card shadow={false} className="bg-white border-2 border-emerald-500/30">
            <h4 className="flex items-center gap-4 mb-4 text-base font-bold text-emerald-600">
              <span className="flex items-center justify-center flex-shrink-0 w-5 h-5 text-white rounded-full bg-emerald-600">
                <Check strokeWidth={3} className="w-3 h-3" />
              </span>
              {t('frontend:donation_success')}
            </h4>
            <div>
              {donationForm.success_message && (
                <HTML value={donationForm.success_message} />
              )}
              {!donationForm.success_message && t('frontend:donation_thank_you_message')}
            </div>
          </Card>
        )}

        {donation && (
          <>
            {!donation.active && donation.payment_status === PaymentStatus.PENDING && (
              <Card shadow={false} className="font-medium bg-white border-2 border-amber-500">
                {t('frontend:donation_pending')}
              </Card>
            )}
            {!donation.active && donation.payment_status !== PaymentStatus.PENDING && (
              <Card shadow={false} className="font-medium bg-white border-2 border-red-500">
                {t('frontend:donation_failed')}
              </Card>
            )}
          </>
        )}
      </Container>

      <Footer charity={charity} />
    </>
  );
}

DonationFormPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
