import { DonationFrequency } from './types';

export enum EventType {
  WidgetClosed = 'Supporta:WidgetClosed',
  NewDonation = 'Supporta:NewDonation',
  NewCampaign = 'Supporta:NewCampaign',
}

/**
 * Send the event to the parent website that is using the Supporta embed.
 */
const dispatch = (payload: object) => {
  window.parent.postMessage(payload, '*');
};

export interface Payload {
  type: EventType;
}

export interface NewCampaignPayload {
  charity: {
    id: string;
    title: string;
  };
  project: {
    id: string;
    title: string;
  };
  campaign: {
    id: string;
    title: string;
  };
}

export const dispatchNewCampaignEvent = (payload: NewCampaignPayload) => {
  dispatch({
    type: EventType.NewCampaign,
    charity: {
      id: payload.charity.id,
      title: payload.charity.title,
    },
    project: {
      id: payload.project.id,
      title: payload.project.title,
    },
    campaign: {
      id: payload.campaign.id,
      title: payload.campaign.title,
    },
  });
};

export interface NewDonationPayload {
  charity: {
    id: string;
    title: string;
  };
  project?: {
    id: string;
    title: string;
  };
  campaign?: {
    id: string;
    title: string;
  },
  donation_form?: {
    id: string;
    title: string;
  },
  donation: {
    id: string;
    amount: number;
    frequency: 'oneoff' | DonationFrequency;
  };
}

export const dispatchNewDonationEvent = (payload: NewDonationPayload) => {
  dispatch({
    type: EventType.NewDonation,
    charity: {
      id: payload.charity.id,
      title: payload.charity.title,
    },
    project: payload.project ? {
      id: payload.project.id,
      title: payload.project.title,
    } : null,
    campaign: payload.campaign ? {
      id: payload.campaign.id,
      title: payload.campaign.title,
    } : null,
    donation_form: payload.donation_form ? {
      id: payload.donation_form.id,
      title: payload.donation_form.title,
    } : null,
    donation: {
      id: payload.donation.id,
      amount: payload.donation.amount,
      frequency: payload.donation.frequency,
    },
  });
};
