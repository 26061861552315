import classNames from 'classnames';
import { useState } from 'react';
import { Check, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { Charity, DonationFrequency } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import InfoMessage from '../../Shared/UI/InfoMessage';
import { Input } from '../../Shared/UI/Input';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import { Radio } from '../../Shared/UI/Radio';
import RadioLabel from '../../Shared/UI/RadioLabel';
import { Select } from '../../Shared/UI/Select';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { useCurrencyInput } from '../../Shared/useCurrencyInput';
import { useRouter } from '../router';
import { Button } from '../UI/Button';
import { cancellationReasons, Subscription } from './helpers';

const minAmount = 100;

export const RecurringDonationCard = ({
  subscription,
  charity,
}: {
  subscription: Subscription;
  charity: Charity;
}) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<'overview' | 'change' | 'cancel'>('overview');

  return (
    <Card border className="space-y-6">
      <FadeIn
        when={tab === 'overview'}
        className="flex justify-between items-center"
        render={() => (
          <>
            <h3>
              {t('frontend:overview')}
            </h3>
            <Button
              onClick={() => setTab('change')}
              variant="secondary"
              size="sm"
            >
              {subscription.active ? t('frontend:change') : t('frontend:resume_donation')}
            </Button>
          </>
        )}
      />
      <FadeIn
        when={tab !== 'overview'}
        className="flex justify-between items-center"
        render={() => (
          <>
            <div className="space-x-1">
              <a
                onClick={() => setTab('change')}
                role="button"
                className={tabClasses({ active: tab === 'change' })}
              >
                {subscription.active ? t('frontend:change') : t('frontend:resume_donation')}
              </a>
              {subscription.active && (
                <a
                  onClick={() => setTab('cancel')}
                  role="button"
                  className={tabClasses({ active: tab === 'cancel' })}
                >
                  {t('frontend:stop')}
                </a>
              )}
            </div>
            <Button
              onClick={() => setTab('overview')}
              variant="tertiary"
              size="sm"
              className="text-slate-400 hover:text-slate-700 transition !p-0.5"
              aria-label={t('frontend:back')}
            >
              <X strokeWidth={1.5} className="block w-6 h-6 mx-auto" />
            </Button>
          </>
        )}
      />

      <hr />

      <FadeIn
        when={tab === 'overview'}
        render={() => (
          <DonationDetails subscription={subscription} charity={charity} />
        )}
      />

      <FadeIn
        when={tab === 'change'}
        render={() => (
          <EditDonationForm subscription={subscription} onClose={() => setTab('overview')} />
        )}
      />

      <FadeIn
        when={tab === 'cancel'}
        render={() => (
          <TerminateDonationForm subscription={subscription} charity={charity} onClose={() => setTab('overview')} />
        )}
      />
    </Card>
  );
};

const DonationDetails = ({
  subscription,
  charity,
}: {
  subscription: Subscription;
  charity: Charity;
}) => {
  const { t } = useTranslation();
  const { formatCurrency, formatDate } = useLocale();

  return (
    <div className="space-y-4">
      <div>
        <h4 className="font-medium text-slate-500">
          {t('frontend:your_donation')}
        </h4>
        <div>
          {subscription.active && subscription.frequency === DonationFrequency.Monthly && t('frontend:your_donation_amount_per_month', {
            amount: formatCurrency(subscription.amount),
            charity: charity.title,
          })}
          {subscription.active && subscription.frequency === DonationFrequency.Yearly && t('frontend:your_donation_amount_per_year', {
            amount: formatCurrency(subscription.amount),
            charity: charity.title,
          })}
          {!subscription.active && subscription.end_date && t('frontend:donation_cancelled_at_description', {
            date: formatDate(subscription.end_date, 'display_full_date'),
          })}
        </div>
      </div>
      {subscription.next_payment_date && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:next_collection')}
          </h4>
          {formatDate(subscription.next_payment_date, 'display_full_date')}
        </div>
      )}
      {subscription.active && subscription.end_date && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:end_date')}
          </h4>
          {formatDate(subscription.end_date, 'display_full_date')}
        </div>
      )}
      {subscription.active && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:iban')}
          </h4>
          {subscription.iban}
        </div>
      )}
    </div>
  );
};

const EditDonationForm = ({
  subscription,
  onClose,
}: {
  subscription: Subscription;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { handleCurrencyBlur, formatCurrency: formatInputCurrency, parseCurrency } = useCurrencyInput();
  const { routes } = useRouter();
  const { formatCurrency } = useLocale();
  const notifier = useNotifier();

  const form = useForm({
    defaultValues: {
      frequency: subscription.frequency,
      amount: formatInputCurrency(subscription.amount),
      iban: subscription.iban,
    },
  });

  const {
    register, setValue, handleSubmit, setError, watch,
    formState: { errors, defaultValues },
  } = form;

  const frequency = watch('frequency');

  const [editSubscription, { errors: serverErrors }] = useMutation(routes.edit_subscription(subscription.id), {
    preserveScroll: true,
    onSuccess: () => {
      notifier.notify(t('frontend:donation_updated'));
      onClose();
    },
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  const submit = async (data: NonNullable<typeof defaultValues>) => {
    await editSubscription({
      frequency: data?.frequency,
      amount: data?.amount ? parseCurrency(data.amount) : null,
      iban: data?.iban,
      active: ! subscription.active ? true : null,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

      <InputGroup>
        <InputLabel required valid={!errors.frequency}>
          {t('frontend:frequency')}
        </InputLabel>
        <div className="flex space-x-4">
          <RadioLabel>
            <Radio
              {...register('frequency', { required: true })}
              value={DonationFrequency.Monthly}
              aria-invalid={!!errors.frequency}
              className="mt-[3px] mr-2"
            />
            {t('frontend:monthly')}
          </RadioLabel>
          <RadioLabel>
            <Radio
              {...register('frequency', { required: true })}
              value={DonationFrequency.Yearly}
              aria-invalid={!!errors.frequency}
              className="mt-[3px] mr-2"
            />
            {t('frontend:yearly')}
          </RadioLabel>
        </div>
      </InputGroup>

      <InputGroup>
        <InputLabel required valid={!errors.amount} htmlFor="subscription_amount">
          {t('frontend:new_amount')}
        </InputLabel>
        <InputWrapper>
          <label>
            €
          </label>
          <Input
            {...register('amount', { required: true })}
            id="subscription_amount"
            className={classNames(
              'pl-7',
            )}
            onBlur={(event) => {
              handleCurrencyBlur(event);
              setValue('amount', event.target.value);
            }}
            aria-invalid={!!errors.amount}
            min={minAmount}
          />
        </InputWrapper>
        <div>
          {frequency === DonationFrequency.Monthly && (
            <Button
              onClick={() => setValue('amount', formatInputCurrency(500))}
              variant="tertiary"
              className="!bg-slate-100 mt-2 mr-2"
              size="sm"
            >
              {formatCurrency(500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </Button>
          )}
          {frequency === DonationFrequency.Monthly && (
            <Button
              onClick={() => setValue('amount', formatInputCurrency(1000))}
              variant="tertiary"
              className="!bg-slate-100 mt-2 mr-2"
              size="sm"
            >
              {formatCurrency(1000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </Button>
          )}
          {frequency === DonationFrequency.Monthly && (
            <Button
              onClick={() => setValue('amount', formatInputCurrency(1500))}
              variant="tertiary"
              className="!bg-slate-100 mt-2 mr-2"
              size="sm"
            >
              {formatCurrency(1500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </Button>
          )}
          <Button
            onClick={() => setValue('amount', formatInputCurrency(2500))}
            variant="tertiary"
            className="!bg-slate-100 mt-2 !mr-2"
            size="sm"
          >
            {formatCurrency(2500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Button>
          <Button
            onClick={() => setValue('amount', formatInputCurrency(5000))}
            variant="tertiary"
            className="!bg-slate-100 mt-2 mr-2"
            size="sm"
          >
            {formatCurrency(5000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Button>
          {frequency === DonationFrequency.Yearly && (
            <Button
              onClick={() => setValue('amount', formatInputCurrency(7500))}
              variant="tertiary"
              className="!bg-slate-100 mt-2 mr-2"
              size="sm"
            >
              {formatCurrency(7500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </Button>
          )}
          {frequency === DonationFrequency.Yearly && (
            <Button
              onClick={() => setValue('amount', formatInputCurrency(10000))}
              variant="tertiary"
              className="!bg-slate-100 mt-2 mr-2"
              size="sm"
            >
              {formatCurrency(10000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </Button>
          )}
          {frequency === DonationFrequency.Yearly && (
            <Button
              onClick={() => setValue('amount', formatInputCurrency(15000))}
              variant="tertiary"
              className="!bg-slate-100 mt-2"
              size="sm"
            >
              {formatCurrency(15000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </Button>
          )}
        </div>
        <ErrorMessage error={errors.amount} attribute={t('frontend:new_amount')} />
      </InputGroup>

      <InputGroup>
        <InputLabel required valid={!errors.iban} htmlFor="subscription_iban">
          {t('frontend:iban')}
        </InputLabel>
        <Input
          {...register('iban', { required: true })}
          id="subscription_iban"
          aria-invalid={!!errors.iban}
        />
        <ErrorMessage attribute={t('frontend:iban')} error={errors.iban }/>
      </InputGroup>

      <hr />

      <div className="flex justify-end space-x-2">
        <Button variant="tertiary" onClick={() => onClose()}>
          {t('frontend:back')}
        </Button>
        <Button type="submit">
          {t('frontend:confirm')}
        </Button>
      </div>
    </form>
  );
};

const TerminateDonationForm = ({
  subscription,
  charity,
  onClose,
}: {
  subscription: Subscription;
  charity: Charity;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { handleCurrencyBlur, formatCurrency: formatInputCurrency, parseCurrency } = useCurrencyInput();
  const { routes } = useRouter();
  const { formatCurrency } = useLocale();
  const notifier = useNotifier();

  const showCancelOptions = subscription.amount > minAmount || subscription.frequency === DonationFrequency.Monthly;

  const form = useForm({
    defaultValues: {
      cancel_option: !showCancelOptions ? 'cancel' : null as 'lower_amount' | 'switch_to_yearly' | 'cancel' | null,
      amount: formatInputCurrency(Math.max(Math.floor(subscription.amount * 0.7 / 100) * 100, 100)),
      cancellation_reason: '',
      cancellation_description: '',
    },
  });

  const {
    register, setValue, handleSubmit, setError, watch,
    formState: { errors, defaultValues },
  } = form;

  const cancelOption = watch('cancel_option');
  const parsedAmount = parseCurrency(watch('amount'));

  const [editSubscription, { errors: serverErrors }] = useMutation(routes.edit_subscription(subscription.id), {
    preserveScroll: true,
    onSuccess: () => {
      notifier.notify(t('frontend:donation_updated'));
      onClose();
    },
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  const submit = async (data: NonNullable<typeof defaultValues>) => {
    await editSubscription({
      amount: data?.cancel_option === 'lower_amount' && data?.amount ? parseCurrency(data.amount) : null,
      frequency: data?.cancel_option === 'switch_to_yearly' ? DonationFrequency.Yearly : null,
      active: data?.cancel_option === 'cancel' ? false : null,
      cancellation_reason: data?.cancel_option === 'cancel' ? data?.cancellation_reason : null,
      cancellation_description: data?.cancel_option === 'cancel' ? data?.cancellation_description : null,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

      {showCancelOptions && (
        <InputGroup>
          <InputLabel required valid={!errors.cancel_option}>
            {t('frontend:make_a_choice')}
          </InputLabel>
          {subscription.amount > minAmount && (
            <RadioLabel>
              <Radio
                {...register('cancel_option', { required: true })}
                value="lower_amount"
                aria-invalid={!!errors.cancel_option}
                className="mt-[3px] mr-2"
              />
              {t('frontend:lower_donation_amount')}
            </RadioLabel>
          )}
          {subscription.frequency === DonationFrequency.Monthly && (
            <RadioLabel>
              <Radio
                {...register('cancel_option', { required: true })}
                value="switch_to_yearly"
                aria-invalid={!!errors.cancel_option}
                className="mt-[3px] mr-2"
              />
              {t('frontend:switch_to_yearly')}
            </RadioLabel>
          )}
          <RadioLabel>
            <Radio
              {...register('cancel_option', { required: true })}
              value="cancel"
              aria-invalid={!!errors.cancel_option}
              className="mt-[3px] mr-2"
            />
            {t('frontend:stop_donation')}
          </RadioLabel>
          <ErrorMessage error={errors.cancel_option} attribute={t('frontend:make_a_choice')} />
        </InputGroup>
      )}

      <FadeIn
        when={cancelOption === 'lower_amount'}
        render={() => (
          <InputGroup>
            <InputLabel required valid={!errors.amount} htmlFor="subscription_amount">
              {t('frontend:new_amount')}
            </InputLabel>
            <InputWrapper>
              <span>
                €
              </span>
              <Input
                {...register('amount', { required: true })}
                id="subscription_amount"
                className="pl-7"
                onBlur={(event) => {
                  handleCurrencyBlur(event);
                  setValue('amount', event.target.value);
                }}
                aria-invalid={!!errors.amount}
                min={minAmount}
              />
            </InputWrapper>
            <ErrorMessage error={errors.amount} attribute={t('frontend:new_amount')} />
          </InputGroup>
        )}
      />

      <FadeIn
        when={cancelOption === 'lower_amount' && !!parsedAmount}
        render={() => (
          <InfoMessage variant="success" icon={<Check />}>
            {t('frontend:lower_donation_amount_description', {
              amount: formatCurrency(parsedAmount || 0),
            })}
          </InfoMessage>
        )}
      />

      <FadeIn
        when={cancelOption === 'switch_to_yearly'}
        render={() => (
          <InfoMessage variant="success" icon={<Check />}>
            {t('frontend:switch_to_yearly_description', {
              amount: formatCurrency(subscription.amount),
            })}
          </InfoMessage>
        )}
      />

      <FadeIn
        when={cancelOption === 'cancel'}
        className="space-y-6"
        render={() => (
          <>
            <InputGroup>
              <InputLabel valid={!errors.cancellation_reason} htmlFor="subscription_cancellation_reason">
                {t('frontend:let_us_know_cancellation_reason')}
              </InputLabel>
              <Select
                {...register('cancellation_reason')}
                id="subscription_cancellation_reason"
                aria-invalid={!!errors.cancellation_reason}
              >
                <option value=""></option>
                {cancellationReasons.map((reason) => (
                  <option value={reason} key={reason}>
                    {t(`frontend:cancellation_reasons.${reason}`)}
                  </option>
                ))}
              </Select>
              <ErrorMessage attribute={t('frontend:let_us_know_cancellation_reason')} error={errors.cancellation_reason }/>
            </InputGroup>

            <InputGroup>
              <InputLabel valid={!errors.amount} htmlFor="subscription_cancellation_description">
                {t('frontend:cancellation_description_optional')}
              </InputLabel>
              <Input {...register('cancellation_description')} id="subscription_cancellation_description" />
              <ErrorMessage error={errors.cancellation_description} attribute={t('frontend:cancellation_description_optional')} />
            </InputGroup>

            <InfoMessage variant="alert">
              {t('frontend:stop_donation_description', { charity: charity.title })}
            </InfoMessage>
          </>
        )}
      />

      <hr />

      <div className="flex justify-end space-x-2">
        <Button variant="tertiary" onClick={() => onClose()}>
          {t('frontend:back')}
        </Button>
        <Button type="submit">
          {t('frontend:confirm')}
        </Button>
      </div>
    </form>
  );
};

const tabClasses = ({
  active,
}: {
  active?: boolean;
}) => {
  return classNames(
    'font-medium py-1 px-2 inline-block rounded',
    active
      ? 'bg-project/15 text-slate-800 hover:text-slate-800'
      : 'hover:bg-project/5 text-slate-500 hover:text-slate-800'
  );
};
