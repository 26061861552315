import { ReactNode } from 'react';
import { Calendar, Globe } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { prettifyUrl } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { Event, Pagination } from '../../Shared/types';
import { LinkButton } from '../../Shared/UI/Button';
import { Card } from '../../Shared/UI/Card';
import DateRange from '../../Shared/UI/DateRange';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchFilterInput } from '../../Shared/UI/SearchFilterInput';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import { Background } from './Background';

export interface EventsPageProps extends SharedProps {
  events: Pagination<Event>;
}

export default function EventsPage({
  events,
  contentNav,
  auth: {
    user,
  },
}: EventsPageProps) {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();
  const { routes } = useRouter();

  return (
    <>
      <NavBar navItems={contentNav} user={user} />

      <Background />

      <div className="relative z-2">

        <Container size="lg" spacing="lg">
          <div className="max-w-screen-lg mx-auto text-center">
            <h1 className="text-3xl mb-4 text-balance">
              {t('frontend:homepage.all_events')}
            </h1>
            <p className="text-lg sm:text-balance">
              {t('frontend:homepage.all_events_description')}
            </p>
          </div>

          <div className="mx-auto max-w-screen-md">
            <SearchFilterInput
              inputSize="lg"
              className="!rounded-xl sm:shadow-[0_1px_2px_0_rgb(0_0_0_/_0.05),0_10px_15px_-3px_rgb(0_0_0_/_0.05),_0_2px_4px_-2px_rgb(0_0_0_/_0.05)]"
              aria-label={t('shared:filter.search')}
            />
          </div>

          {events.meta.total > 0 && (
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {events.data.map((event) => (
                <Card
                  className="text-center min-w-0 flex flex-col items-stretch"
                  spacing="sm"
                  panels
                  shadow={false}
                  border
                  key={event.id}
                >
                  <div>
                    <div className="w-20 h-20 rounded-full bg-white shadow-[0_5px_15px_-3px_rgb(0_0_0_/_0.05),_0_4px_6px_-4px_rgb(0_0_0_/_0.08)] p-1.5 mx-auto mb-4">
                      <img src={event.logo_url || ''} alt={event.title} className="object-contain h-full w-full rounded-full" />
                    </div>
                    <h3 className="overflow-hidden whitespace-nowrap text-ellipsis">
                      {event.title}
                    </h3>
                    <div className="text-sm text-slate-500">
                      <Icon className="mr-1">
                        <Calendar />
                      </Icon>
                      <DateRange start={event.start_date} end={event.end_date} />
                    </div>
                    {event.website && (
                      <div className="text-sm text-slate-500">
                        <a
                          href={event.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-slate-500"
                        >
                          <Icon className="mr-1">
                            <Globe />
                          </Icon>
                          {prettifyUrl(event.website)}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="space-y-4 mt-auto">
                    <div className="grid grid-cols-2 gap-2 text-sm">
                      <div>
                        <strong>
                          {formatCurrency(event.funds_raised, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </strong>
                        <br />
                        {t('frontend:raised')}
                      </div>
                      <div>
                        <strong className="">
                          {formatNumber(event.campaigns_count)}
                        </strong>
                        <br />
                        {t('frontend:campaigns', { count: event.campaigns_count })}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <LinkButton
                        href={routes.home_page(null, event.slug)}
                        className="block w-full"
                        variant="secondary"
                        size="sm"
                        disabled={event.campaigns_count === 0}
                      >
                        {t('frontend:view_campaigns')}
                      </LinkButton>
                      <LinkButton
                        href={routes.select_charity_page(null, { eventId: event.slug })}
                        className="block w-full"
                        size="sm"
                      >
                        {t('frontend:start_campaign')}
                      </LinkButton>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )}

          {events.meta.total === 0 && (
            <div className="text-center text-xl font-semibold py-16">
              <SupportaSymbol className="text-slate-200 w-12 mx-auto mb-4" />
              {t('frontend:no_results')}
            </div>
          )}

          {events.meta.last_page > 1 && (
            <Pager data={events} preserveScroll={false} />
          )}
        </Container>

        <Footer links={contentNav} />
      </div>
    </>
  );
}

EventsPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
