import { useState } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import { containsAddress } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { DonationForm, DonorType, donorTypes, Gender } from '../../Shared/types';
import Address from '../../Shared/UI/Address';
import AddressInputs from '../../Shared/UI/AddressInputs';
import { Card } from '../../Shared/UI/Card';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import InfoMessage from '../../Shared/UI/InfoMessage';
import { Input } from '../../Shared/UI/Input';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import PhoneNumberInput from '../../Shared/UI/PhoneNumberInput';
import { Radio } from '../../Shared/UI/Radio';
import RadioLabel from '../../Shared/UI/RadioLabel';
import { Select } from '../../Shared/UI/Select';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { useDateInput } from '../../Shared/useDateInput';
import { useRouter } from '../router';
import { Button } from '../UI/Button';
import { Subscription } from './helpers';

export const PersonalDetailsCard = ({
  subscription,
  donationForm,
}: {
  subscription: Subscription;
  donationForm: DonationForm;
}) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<'overview' | 'change'>('overview');

  return (
    <Card border className="space-y-6">
      <FadeIn
        when={tab === 'overview'}
        render={() => (
          <div className="flex justify-between items-center">
            <h3>
              {t('frontend:overview')}
            </h3>
            <Button
              onClick={() => setTab('change')}
              variant="secondary"
              size="sm"
            >
              {t('frontend:change')}
            </Button>
            {tab === 'change' && (
              <Button
                onClick={() => setTab('overview')}
                variant="tertiary"
                size="sm"
                className="text-slate-400 hover:text-slate-700 transition !p-0.5"
                aria-label={t('frontend:cancel')}
              >
                <X strokeWidth={1.5} className="block w-6 h-6 mx-auto" />
              </Button>
            )}
          </div>
        )}
      />

      <FadeIn
        when={tab === 'change'}
        render={() => (
          <div className="flex justify-between items-center">
            <h3>
              {t('frontend:edit_details')}
            </h3>
            <Button
              onClick={() => setTab('overview')}
              variant="tertiary"
              size="sm"
              className="text-slate-400 hover:text-slate-700 transition !p-0.5"
              aria-label={t('frontend:cancel')}
            >
              <X strokeWidth={1.5} className="block w-6 h-6 mx-auto" />
            </Button>
          </div>
        )}
      />

      <hr />

      <FadeIn
        when={tab === 'overview'}
        render={() => (
          <PersonalDetails subscription={subscription} donationForm={donationForm} />
        )}
      />

      <FadeIn
        when={tab === 'change'}
        render={() => (
          <EditPersonalDetailsForm
            subscription={subscription}
            donationForm={donationForm}
            onClose={() => setTab('overview')}
          />
        )}
      />
    </Card>
  );
};

const PersonalDetails = ({
  subscription,
  donationForm,
}: {
  subscription: Subscription;
  donationForm: DonationForm;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useLocale();

  return (
    <div className="space-y-4">
      <div>
        <h4 className="font-medium text-slate-500">
          {t('frontend:name')}
        </h4>
        {subscription.full_name}
      </div>
      <div>
        <h4 className="font-medium text-slate-500">
          {t('frontend:email_address')}
        </h4>
        {subscription.email}
      </div>
      {donationForm.fields.includes('gender') && subscription.gender && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:gender')}
          </h4>
          {t(`shared:genders.${subscription.gender}`)}
        </div>
      )}
      {donationForm.fields.includes('date_of_birth') && subscription.date_of_birth && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:date_of_birth')}
          </h4>
          {formatDate(subscription.date_of_birth, 'display_full_date')}
        </div>
      )}
      <div>
        <h4 className="font-medium text-slate-500">
          {t('frontend:donate_as')}
        </h4>
        {t(`frontend:donor_types.${subscription.donor_type}`)}
      </div>
      {subscription.company_name && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:company_name')}
          </h4>
          {subscription.company_name}
        </div>
      )}
      {donationForm.fields.includes('phone') && subscription.phone && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:phone_number')}
          </h4>
          {subscription.phone}
        </div>
      )}
      {donationForm.fields.includes('address') && subscription.street && (
        <div>
          <h4 className="font-medium text-slate-500">
            {t('frontend:address')}
          </h4>
          <Address {...subscription} />
        </div>
      )}
    </div>
  );
};

const EditPersonalDetailsForm = ({
  subscription,
  donationForm,
  onClose,
}: {
  subscription: Subscription;
  donationForm: DonationForm;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { formatDate, defaultCountry, parseDate } = useLocale();
  const { handleDateBlur } = useDateInput();
  const { routes } = useRouter();
  const notifier = useNotifier();

  const form = useForm({
    defaultValues: {
      donor_type: subscription.donor_type,
      first_name: subscription.first_name,
      last_name: subscription.last_name,
      email: subscription.email,
      gender: subscription.gender,
      date_of_birth: subscription.date_of_birth ? formatDate(subscription.date_of_birth, 'input_date') : '',
      company_name: subscription.company_name,
      phone: subscription.phone,
      street: subscription.street,
      house_number: subscription.house_number,
      extra_address_line: subscription.extra_address_line,
      zip_code: subscription.zip_code,
      city: subscription.city,
      country: subscription.country || defaultCountry,
    },
  });

  const {
    register, setValue, handleSubmit, setError, control, watch,
    formState: { errors, defaultValues },
  } = form;

  const email = watch('email');
  const donorType = watch('donor_type');

  const requireCompanyName = [DonorType.Business, DonorType.Foundation].includes(donorType);

  const [editSubscription, { errors: serverErrors }] = useMutation(routes.edit_subscription(subscription.id), {
    preserveScroll: true,
    onSuccess: () => {
      notifier.notify(t('frontend:donation_updated'));
      onClose();
    },
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  const submit = async (data: NonNullable<typeof defaultValues>) => {
    const dateOfBirth = data?.date_of_birth && parseDate(data.date_of_birth, 'input_date');

    await editSubscription({
      donor_type: data?.donor_type,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      company_name: data?.company_name,
      ...donationForm.fields.includes('gender') && {
        gender: data?.gender,
      },
      ...donationForm.fields.includes('date_of_birth') && {
        date_of_birth: dateOfBirth ? formatDate(dateOfBirth, 'internal_date') : null,
      },
      ...donationForm.fields.includes('phone') && {
        phone: data?.phone,
      },
      ...donationForm.fields.includes('address') && {
        street: data?.street,
        house_number: data?.house_number,
        extra_address_line: data?.extra_address_line,
        zip_code: data?.zip_code,
        city: data?.city,
        country: containsAddress(data) ? data?.country : null,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

      <div className="grid grid-cols-2 gap-6">
        <InputGroup>
          <InputLabel required valid={!errors.first_name} htmlFor="subscription_first_name">
            {t('frontend:first_name')}
          </InputLabel>
          <Input
            {...register('first_name', { required: true })}
            id="subscription_first_name"
            aria-invalid={!!errors.first_name}
          />
          <ErrorMessage attribute={t('frontend:first_name')} error={errors.first_name }/>
        </InputGroup>

        <InputGroup>
          <InputLabel required valid={!errors.last_name} htmlFor="subscription_last_name">
            {t('frontend:last_name')}
          </InputLabel>
          <Input
            {...register('last_name', { required: true })}
            id="subscription_last_name"
            aria-invalid={!!errors.last_name}
          />
          <ErrorMessage attribute={t('frontend:last_name')} error={errors.last_name }/>
        </InputGroup>
      </div>

      <InputGroup>
        <InputLabel required valid={!errors.email} htmlFor="subscription_email">
          {t('frontend:email_address')}
        </InputLabel>
        <Input
          {...register('email', { required: true })}
          inputMode="email"
          id="subscription_email"
          aria-invalid={!!errors.email}
        />
        <ErrorMessage attribute={t('frontend:email_address')} error={errors.email }/>
        {email !== subscription.email && (
          <InfoMessage variant="alert" className="mt-4">
            {t('frontend:edit_email_message')}
          </InfoMessage>
        )}
      </InputGroup>

      {donationForm.fields.includes('gender') && (
        <InputGroup>
          <InputLabel required valid={!errors.gender} htmlFor="subscription_gender">
            {t('frontend:gender')}
          </InputLabel>
          <div className="flex space-x-3">
            <RadioLabel>
              <Radio
                {...register('gender', { required: true })}
                value={Gender.Male}
                aria-invalid={!!errors.gender}
              />
              <span>
                {t('shared:genders.male')}
              </span>
            </RadioLabel>
            <RadioLabel>
              <Radio
                {...register('gender', { required: true })}
                value={Gender.Female}
                aria-invalid={!!errors.gender}
              />
              <span>
                {t('shared:genders.female')}
              </span>
            </RadioLabel>
            <RadioLabel>
              <Radio
                {...register('gender', { required: true })}
                value={Gender.Nonbinary}
                aria-invalid={!!errors.gender}
              />
              <span>
                {t('shared:genders.nonbinary')}
              </span>
            </RadioLabel>
          </div>
          <ErrorMessage error={errors.gender} attribute={t('frontend:gender')} />
        </InputGroup>
      )}

      {donationForm.fields.includes('date_of_birth') && (
        <InputGroup>
          <InputLabel required htmlFor="subscription_date_of_birth" valid={!errors.date_of_birth} >
            {t('frontend:date_of_birth')}
          </InputLabel>
          <Input
            {...register('date_of_birth', { required: true })}
            onBlur={(event) => {
              handleDateBlur(event);
              setValue('date_of_birth', event.target.value);
            }}
            id="subscription_date_of_birth"
            placeholder={t('shared:date_input.date_format_placeholder')}
            aria-invalid={!!errors.date_of_birth}
          />
          <ErrorMessage error={errors.date_of_birth} attribute={t('frontend:date_of_birth')} />
        </InputGroup>
      )}

      <InputGroup>
        <InputLabel required valid={!errors.donor_type} htmlFor="subscription_donor_type">
          {t('frontend:donate_as')}
        </InputLabel>
        <Select
          {...register('donor_type')}
          id="subscription_donor_type"
          aria-invalid={!!errors.donor_type}
        >
          {donorTypes.map((donorType) => (
            <option value={donorType} key={donorType}>
              {t(`frontend:donor_types.${donorType}`)}
            </option>
          ))}
        </Select>
        <ErrorMessage attribute={t('frontend:donate_as')} error={errors.donor_type }/>
      </InputGroup>

      <FadeIn
        when={requireCompanyName}
        render={() => (
          <InputGroup>
            <InputLabel required valid={!errors.company_name} htmlFor="subscription_company_name">
              {t('frontend:company_name')}
            </InputLabel>
            <Input
              {...register('company_name', { required: true })}
              id="subscription_company_name"
              aria-invalid={!!errors.company_name}
            />
            <ErrorMessage error={errors.company_name} attribute={t('frontend:company_name')} />
          </InputGroup>
        )}
      />

      {donationForm.fields.includes('phone') && (
        <InputGroup>
          <InputLabel htmlFor="subscription_phone">
            {t('frontend:phone_number')}
          </InputLabel>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, onBlur, value } }) => (
              <PhoneNumberInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                country={subscription.country}
                id="subscription_phone"
                ariaInvalid={!!errors.phone}
              />
            )}
          />
          <ErrorMessage error={errors.phone} attribute={t('frontend:phone_number')} />
        </InputGroup>
      )}

      {donationForm.fields.includes('address') && (
        <AddressInputs form={form} required />
      )}

      <hr />

      <div className="flex justify-end space-x-2">
        <Button variant="tertiary" onClick={() => onClose()}>
          {t('frontend:back')}
        </Button>
        <Button type="submit">
          {t('frontend:save')}
        </Button>
      </div>
    </form>
  );
};
