import { AddressFields, CancellationReason, DateOfBirthFields, DonorType, PhoneFields, Subscription as BaseSubscription } from '../../Shared/types';

export interface Subscription extends BaseSubscription, PhoneFields, DateOfBirthFields, AddressFields {
  email: string;
  email_opt_in: boolean;
  donor_type: DonorType;
  company_name: string | null;
  first_name: string;
  last_name: string;
  full_name: string;
  gender: string | null;
  start_date: string;
  end_date: string | null;
  next_payment_date: string | null;
  iban: string;
  cancellation_reason: string | null;
  active: boolean;
}

export const cancellationReasons = [
  CancellationReason.FinancialReasons,
  CancellationReason.PersonalReasons,
  CancellationReason.Complaint,
  CancellationReason.LostInterest,
  CancellationReason.SwitchingCharity,
  CancellationReason.PredeterminedEndDate,
  CancellationReason.Other,
];
