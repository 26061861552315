import { Page } from '@inertiajs/core';
import classNames from 'classnames';
import { Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { HelpCircle, Lock, XCircle } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import { useLocale } from '../../Shared/locale';
import { getPaymentMethodLogo, supportsApplePay } from '../../Shared/paymentMethods';
import { getTrackingData } from '../../Shared/tracking';
import { Charity, DonationForm, DonationFrequency, DonorType, donorTypes, Gender, PaymentMethod, PaymentMethodType } from '../../Shared/types';
import AddressInputs from '../../Shared/UI/AddressInputs';
import { Checkbox } from '../../Shared/UI/Checkbox';
import CheckboxLabel from '../../Shared/UI/CheckboxLabel';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { HTML } from '../../Shared/UI/HTML';
import { Icon } from '../../Shared/UI/Icon';
import InfoMessage from '../../Shared/UI/InfoMessage';
import { Input } from '../../Shared/UI/Input';
import InputDescription from '../../Shared/UI/InputDescription';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import PhoneNumberInput from '../../Shared/UI/PhoneNumberInput';
import { Radio } from '../../Shared/UI/Radio';
import { RadioBox } from '../../Shared/UI/RadioBox';
import RadioLabel from '../../Shared/UI/RadioLabel';
import { Select } from '../../Shared/UI/Select';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useCurrencyInput } from '../../Shared/useCurrencyInput';
import { useDateInput } from '../../Shared/useDateInput';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { Button } from '../UI/Button';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { EmbedContext } from '../UI/EmbedProvider';
import { Footer } from '../UI/Footer';
import { NavBar } from '../UI/NavBar';
import PageOfflineWarning from '../UI/PageOfflineWarning';

interface DonationFormPageProps extends SharedProps{
  donationForm: DonationForm;
  paymentMethods: PaymentMethod[];
  continueUrl: string | null;
  charity: Charity;
}

export default function DonationFormPage({
  donationForm,
  paymentMethods,
  charity,
}: DonationFormPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { formatCurrency, parseDate, formatDate, defaultCountry } = useLocale();
  const { handleCurrencyBlur, parseCurrency, formatCurrency: formatInputCurrency } = useCurrencyInput();
  const { handleDateBlur } = useDateInput();
  const { embedUrl } = useContext(EmbedContext);

  useCharityStyle(charity);

  const supportedPaymentMethods = paymentMethods.filter((paymentMethod) => (
    paymentMethod.id !== PaymentMethodType.APPLE_PAY || supportsApplePay()
  ));
  const canReceivePayments = supportedPaymentMethods.length > 0;

  const form = useForm({
    defaultValues: {
      frequency: (donationForm.allow_donations ? 'oneoff' : 'monthly') as 'oneoff' | DonationFrequency,
      iban: '',
      first_name: '',
      last_name: '',
      donor_type: donorTypes[0],
      company_name: '',
      email: '',
      gender: null as Gender | null,
      date_of_birth: '',
      phone: '',
      country: defaultCountry,
      street: '',
      house_number: '',
      extra_address_line: '',
      zip_code: '',
      city: '',
      phone_opt_in: false,
      email_opt_in: false,
      amount: '',
      payment_method: null as PaymentMethodType | null,
      payment_method_issuer: null,
    },
  });

  const {
    register, setValue, handleSubmit, setError, watch, control,
    formState: { errors, defaultValues },
  } = form;

  const frequency = watch('frequency');
  const isRecurring = frequency !== 'oneoff';
  const email = watch('email');
  const donorType = watch('donor_type');
  const phone = watch('phone');
  const gender = watch('gender');

  const enabledFields = donationForm.fields;
  const requireCompanyName = [DonorType.Business, DonorType.Foundation].includes(donorType);

  const selectedPaymentMethod = watch('payment_method');

  useEffect(() => {
    // Select payment payment when there is only one.
    if (supportedPaymentMethods.length === 1 && selectedPaymentMethod !== supportedPaymentMethods[0].id) {
      setValue('payment_method', supportedPaymentMethods[0].id);
      setValue('payment_method_issuer', null);
    }

    // Deselect payment method when it's not available anymore.
    if (selectedPaymentMethod && supportedPaymentMethods.filter((paymentMethod) => paymentMethod.id === selectedPaymentMethod).length === 0) {
      setValue('payment_method', null);
      setValue('payment_method_issuer', null);
    }
  }, [supportedPaymentMethods, selectedPaymentMethod]);

  const amount = parseCurrency(watch('amount')) || 0;
  const donationAmount = amount;
  /** The fee is 15% of the total amount, with a maximum of € 0,95. */
  const platformFee = Math.min(95, Math.round(amount * 0.15));
  const totalAmount = amount + platformFee;

  const [donate, { loading: donating, errors: serverErrors }] = useMutation<Page<DonationFormPageProps>>(
    routes.submit_donation_form(donationForm.id),
    {
      onError: (errors) => {
        setServerErrors(errors, setError, defaultValues);
      },
      onSuccess: (result) => {
        const props = result.props;

        if (props.continueUrl) {
          // Redirect the user to the payment screen, breaking out of the embed iframe.
          // The user can go browse back from the payment screen because the redirection
          // is handled in the onSuccess callback, which is not executed again on browsing back.
          window.parent.location = props.continueUrl;
        }
      },
      preserveScroll: true,
    },
  );

  const submit = (data: typeof defaultValues) => {
    const dateOfBirth = data?.date_of_birth && parseDate(data.date_of_birth, 'input_date');

    donate({
      amount: data?.amount ? parseCurrency(data.amount) : null,
      email: data?.email,
      email_opt_in: data?.email_opt_in,
      donor_type: data?.donor_type,
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender: data?.gender,
      date_of_birth: dateOfBirth ? formatDate(dateOfBirth, 'internal_date') : null,
      company_name: data?.company_name,
      phone: data?.phone,
      phone_opt_in: data?.phone_opt_in,
      country: data?.country,
      street: data?.street,
      house_number: data?.house_number,
      extra_address_line: data?.extra_address_line,
      zip_code: data?.zip_code,
      city: data?.city,
      ...data?.frequency === 'oneoff' && {
        payment_method: data?.payment_method,
        payment_method_issuer: data?.payment_method_issuer,
      },
      ...data?.frequency !== 'oneoff' && {
        iban: data?.iban,
      },
      embed_url: embedUrl,
      ...getTrackingData(),
    });
  };

  const [showPlatformDescription, setShowPlatformDescription] = useState(false);

  return (
    <>
      <NavBar charity={charity} showLoginButton={false} showCampaignButton={false} />

      <Container>
        {!donationForm.enabled && (
          <PageOfflineWarning />
        )}

        <div className="p-6 space-y-4 bg-gradient-to-tl from-project/10 to-project/5 rounded-xl sm:p-8">
          <h1 className={classNames('text-xl font-bold text-slate-800')}>
            {donationForm.title}
          </h1>

          {donationForm.description && (
            <div className="text-slate-600">
              <HTML value={donationForm.description} />
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit(submit)}>
          <div className="space-y-8">
            {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

            <div className="space-y-6">
              <h2 className="flex items-center text-base">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-project">
                  <span className="text-sm font-bold text-project-comp">
                    1
                  </span>
                </div>
                <span className="ml-4 font-medium text-slate-500">
                  {t('frontend:your_donation')}
                </span>
              </h2>

              {donationForm.allow_subscriptions && (
                <InputGroup>
                  <InputLabel required valid={!errors.frequency} htmlFor="donation_frequency">
                    {t('frontend:frequency')}
                  </InputLabel>
                  <InputDescription>
                    {t('frontend:donation_frequency_description')}
                  </InputDescription>
                  <div className="flex space-x-4">
                    {donationForm.allow_donations && (
                      <RadioLabel>
                        <Radio
                          {...register('frequency')}
                          value="oneoff"
                          aria-invalid={!!errors.frequency}
                          className="mt-[3px] mr-2"
                        />
                        {t('frontend:oneoff')}
                      </RadioLabel>
                    )}
                    <RadioLabel>
                      <Radio
                        {...register('frequency', { required: true })}
                        value={DonationFrequency.Monthly}
                        aria-invalid={!!errors.frequency}
                        className="mt-[3px] mr-2"
                      />
                      {t('frontend:monthly')}
                    </RadioLabel>
                    <RadioLabel>
                      <Radio
                        {...register('frequency', { required: true })}
                        value={DonationFrequency.Yearly}
                        aria-invalid={!!errors.frequency}
                        className="mt-[3px] mr-2"
                      />
                      {t('frontend:yearly')}
                    </RadioLabel>
                  </div>
                </InputGroup>
              )}

              <InputGroup>
                <InputLabel valid={!errors.amount} required htmlFor="donation_amount">
                  {t('frontend:amount')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:donation_amount_description', { destination: charity.title })}
                </InputDescription>

                <InputWrapper>
                  <label className="text-2xl ml-4 !px-0 font-bold" htmlFor="donation_amount">
                    €
                  </label>
                  <Input
                    {...register('amount', { required: true })}
                    id="donation_amount"
                    className={classNames(
                      'pl-10 font-bold !h-16 text-2xl placeholder:font-normal',
                    )}
                    onBlur={(event) => {
                      handleCurrencyBlur(event);
                      setValue('amount', event.target.value);
                    }}
                    aria-invalid={!!errors.amount}
                    inputMode="decimal"
                    min={50}
                  />
                </InputWrapper>
                <div>
                  {frequency === DonationFrequency.Monthly && (
                    <Button
                      onClick={() => setValue('amount', formatInputCurrency(500))}
                      variant="tertiary"
                      className="!bg-slate-100 mt-2 mr-2"
                      size="sm"
                    >
                      {formatCurrency(500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Button>
                  )}
                  {frequency !== DonationFrequency.Yearly && (
                    <Button
                      onClick={() => setValue('amount', formatInputCurrency(1000))}
                      variant="tertiary"
                      className="!bg-slate-100 mt-2 mr-2"
                      size="sm"
                    >
                      {formatCurrency(1000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Button>
                  )}
                  {frequency !== DonationFrequency.Yearly && (
                    <Button
                      onClick={() => setValue('amount', formatInputCurrency(1500))}
                      variant="tertiary"
                      className="!bg-slate-100 mt-2 mr-2"
                      size="sm"
                    >
                      {formatCurrency(1500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Button>
                  )}
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(2500))}
                    variant="tertiary"
                    className="!bg-slate-100 mt-2 !mr-2"
                    size="sm"
                  >
                    {formatCurrency(2500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(5000))}
                    variant="tertiary"
                    className="!bg-slate-100 mt-2 mr-2"
                    size="sm"
                  >
                    {formatCurrency(5000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                  {frequency !== DonationFrequency.Monthly && (
                    <Button
                      onClick={() => setValue('amount', formatInputCurrency(7500))}
                      variant="tertiary"
                      className="!bg-slate-100 mt-2 mr-2"
                      size="sm"
                    >
                      {formatCurrency(7500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Button>
                  )}
                  {frequency === DonationFrequency.Yearly && (
                    <Button
                      onClick={() => setValue('amount', formatInputCurrency(10000))}
                      variant="tertiary"
                      className="!bg-slate-100 mt-2 mr-2"
                      size="sm"
                    >
                      {formatCurrency(10000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Button>
                  )}
                  {frequency === DonationFrequency.Yearly && (
                    <Button
                      onClick={() => setValue('amount', formatInputCurrency(15000))}
                      variant="tertiary"
                      className="!bg-slate-100 mt-2"
                      size="sm"
                    >
                      {formatCurrency(15000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Button>
                  )}
                </div>
                <ErrorMessage error={errors.amount} attribute={t('frontend:amount')} />
              </InputGroup>
            </div>

            <hr />

            <div className="space-y-6">
              <h2 className="flex items-center text-base">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-project">
                  <span className="text-sm font-bold text-project-comp">
                    2
                  </span>
                </div>
                <span className="ml-4 font-medium text-slate-500">
                  {isRecurring ? t('frontend:your_details') : t('frontend:your_details_optional')}
                </span>
              </h2>

              <InputGroup>
                <InputLabel valid={!errors.first_name} required={isRecurring} htmlFor="donation_first_name">
                  {t('frontend:name')}
                </InputLabel>
                <FadeIn
                  when={!isRecurring}
                  render={() => (
                    <InputDescription>
                      {t('frontend:donation_name_description')}
                    </InputDescription>
                  )}
                />
                <div className="grid grid-cols-[2fr_3fr] gap-2">
                  <div>
                    <Input
                      {...register('first_name', { required: isRecurring })}
                      id="donation_first_name"
                      aria-invalid={!!errors.first_name}
                      placeholder={t('frontend:first_name')}
                    />
                  </div>
                  <div>
                    <Input
                      {...register('last_name', { required: isRecurring })}
                      id="donation_last_name"
                      aria-invalid={!!errors.last_name}
                      placeholder={t('frontend:last_name')}
                    />
                  </div>
                </div>
                <ErrorMessage error={errors.first_name} attribute={t('frontend:first_name')} />
                <ErrorMessage error={errors.last_name} attribute={t('frontend:last_name')} />
              </InputGroup>

              <FadeIn
                when={isRecurring}
                render={(() => (
                  <InputGroup>
                    <InputLabel valid={!errors.iban} required htmlFor="donation_iban">
                      {t('frontend:iban')}
                    </InputLabel>
                    <InputDescription>
                      {t('frontend:donation_iban_description')}
                    </InputDescription>
                    <Input
                      {...register('iban', { required: true })}
                      id="donation_iban"
                      aria-invalid={!!errors.iban}
                    />
                    <ErrorMessage error={errors.iban} attribute={t('frontend:iban')} />
                  </InputGroup>
                ))}
              />

              <InputGroup>
                <InputLabel valid={!errors.email} required={isRecurring} htmlFor="donation_email">
                  {t('frontend:email_address')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:donation_email_description')}
                </InputDescription>
                <Input
                  {...register('email', { required: isRecurring })}
                  id="donation_email"
                  aria-invalid={!!errors.email}
                />
                <ErrorMessage error={errors.email} attribute={t('frontend:email_address')} />
              </InputGroup>

              <FadeIn
                when={enabledFields.includes('gender')}
                render={() => (
                  <InputGroup>
                    <InputLabel
                      required={isRecurring}
                      valid={!errors.gender}
                      htmlFor="add_donation_form_gender"
                    >
                      {t('frontend:gender')}
                    </InputLabel>
                    <div className="flex space-x-3">
                      <RadioLabel>
                        <Radio
                          {...register('gender', { required: isRecurring })}
                          value={Gender.Male}
                          aria-invalid={!!errors.gender}
                        />
                        <span>
                          {t('shared:genders.male')}
                        </span>
                      </RadioLabel>
                      <RadioLabel>
                        <Radio
                          {...register('gender', { required: isRecurring })}
                          value={Gender.Female}
                          aria-invalid={!!errors.gender}
                        />
                        <span>
                          {t('shared:genders.female')}
                        </span>
                      </RadioLabel>
                      <RadioLabel>
                        <Radio
                          {...register('gender', { required: isRecurring })}
                          value={Gender.Nonbinary}
                          aria-invalid={!!errors.gender}
                        />
                        <span>
                          {t('shared:genders.nonbinary')}
                        </span>
                      </RadioLabel>
                      {gender && !isRecurring && (
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            setValue('gender', null);
                          }}
                          variant="tertiary"
                          size="xs"
                          aria-label={t('shared:filter.clear')}
                        >
                          <Icon>
                            <XCircle />
                          </Icon>
                        </Button>
                      )}
                    </div>
                    <ErrorMessage error={errors.gender} attribute={t('frontend:gender')} />
                  </InputGroup>
                )}
              />

              <FadeIn
                when={enabledFields.includes('date_of_birth')}
                render={() => (
                  <InputGroup>
                    <InputLabel
                      required={isRecurring}
                      valid={!errors.date_of_birth}
                      htmlFor="donation_date_of_birth"
                    >
                      {t('frontend:date_of_birth')}
                    </InputLabel>
                    <Input
                      {...register('date_of_birth', { required: isRecurring })}
                      onBlur={(event) => {
                        handleDateBlur(event);
                        setValue('date_of_birth', event.target.value);
                      }}
                      id="donation_date_of_birth"
                      placeholder={t('shared:date_input.date_format_placeholder')}
                      aria-invalid={!!errors.date_of_birth}
                    />
                    <ErrorMessage error={errors.date_of_birth} attribute={t('frontend:date_of_birth')} />
                  </InputGroup>
                )}
              />

              <InputGroup>
                <InputLabel required={isRecurring} valid={!errors.donor_type} htmlFor="donation_donor_type">
                  {t('frontend:donate_as')}
                </InputLabel>
                <Select
                  {...register('donor_type')}
                  id="donation_donor_type"
                  aria-invalid={!!errors.donor_type}
                >
                  {donorTypes.map((donorType) => (
                    <option value={donorType} key={donorType}>
                      {t(`frontend:donor_types.${donorType}`)}
                    </option>
                  ))}
                </Select>
                <ErrorMessage attribute={t('frontend:donate_as')} error={errors.donor_type }/>
              </InputGroup>

              <FadeIn
                when={requireCompanyName}
                render={() => (
                  <InputGroup>
                    <InputLabel
                      required
                      valid={!errors.company_name}
                      htmlFor="donation_company_name"
                    >
                      {t('frontend:company_name')}
                    </InputLabel>
                    <Input
                      {...register('company_name', { required: true })}
                      id="donation_company_name"
                      aria-invalid={!!errors.company_name}
                    />
                    <ErrorMessage error={errors.company_name} attribute={t('frontend:company_name')} />
                  </InputGroup>
                )}
              />

              <FadeIn
                when={enabledFields.includes('phone')}
                render={() => (
                  <InputGroup>
                    <InputLabel
                      valid={!errors.phone}
                      htmlFor="donation_phone"
                    >
                      {t('frontend:phone_number')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="phone"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <PhoneNumberInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          id="donation_phone"
                          ariaInvalid={!!errors.phone}
                        />
                      )}
                    />
                    <ErrorMessage error={errors.phone} attribute={t('frontend:phone_number')} />
                  </InputGroup>
                )}
              />

              <FadeIn
                when={enabledFields.includes('address')}
                render={() => (
                  <AddressInputs form={form} required={isRecurring} />
                )}
              />

              {(email || isRecurring || phone) && (
                <InputGroup className="space-y-2">
                  <FadeIn
                    when={!!email || isRecurring}
                    render={() => (
                      <CheckboxLabel htmlFor="donation_email_opt_in">
                        <Checkbox
                          {...register('email_opt_in')}
                          id="donation_email_opt_in"
                          className="mt-1"
                        />
                        <span>
                          {t('frontend:email_opt_in_text', { charity: charity.title })}
                        </span>
                      </CheckboxLabel>
                    )}
                  />

                  <FadeIn
                    when={!!phone}
                    render={() => (
                      <CheckboxLabel htmlFor="donation_phone_opt_in">
                        <Checkbox
                          {...register('phone_opt_in')}
                          id="donation_phone_opt_in"
                          className="mt-1"
                        />
                        <span>
                          {t('frontend:phone_opt_in_text', { charity: charity.title })}
                        </span>
                      </CheckboxLabel>
                    )}
                  />
                </InputGroup>
              )}
            </div>

            <hr />

            {frequency === 'oneoff' && (
              <>
                <div className="space-y-6">
                  <h2 className="flex items-center text-base">
                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-project">
                      <span className="text-sm font-bold text-project-comp">
                        3
                      </span>
                    </div>
                    <span className="ml-4 font-medium text-slate-500">
                      {t('frontend:payment')}
                    </span>
                  </h2>

                  {!canReceivePayments && (
                    <FadeIn className="p-6 border rounded-lg border-orange-500/20 bg-orange-50">
                      {t('frontend:no_payment_methods', { charity: charity.title })}
                    </FadeIn>
                  )}

                  {canReceivePayments && (
                    <>
                      <InputGroup>
                        <InputLabel valid={!errors.payment_method} required htmlFor="donation_payment_method_0">
                          {t('frontend:payment_method')}
                        </InputLabel>

                        <div className="space-y-2">
                          {supportedPaymentMethods.map((paymentMethod, i) => (
                            <Fragment key={paymentMethod.id}>
                              <RadioBox size="lg">
                                <Radio
                                  {...register('payment_method', { required: true })}
                                  value={paymentMethod.id}
                                  aria-invalid={!!errors.payment_method}
                                  id={`donation_payment_method_${i}`}
                                  className="mt-[3px] mr-4"
                                />
                                <div className="w-full">
                                  <div className="flex">
                                    <img src={getPaymentMethodLogo(paymentMethod.id)} className="mr-2" />
                                    {t(`shared:payment_method.${paymentMethod.id}`)}
                                  </div>
                                  <FadeIn
                                    when={selectedPaymentMethod === paymentMethod.id && paymentMethod.issuers.length > 0}
                                    className="w-full mt-3"
                                    render={() => (
                                      <>
                                        <Select
                                          {...register('payment_method_issuer', { required: true })}
                                          aria-invalid={!!errors.payment_method_issuer}
                                        >
                                          <option value="">{t('shared:payment_method.make_a_choice')}</option>
                                          {paymentMethod.issuers.map((issuer) => (
                                            <option value={issuer.id} key={issuer.id}>
                                              {issuer.name}
                                            </option>
                                          ))}
                                        </Select>
                                        <ErrorMessage attribute={t('frontend:payment_method')} error={errors.payment_method_issuer} />
                                      </>
                                    )}
                                  />
                                </div>
                              </RadioBox>
                            </Fragment>
                          ))}
                        </div>

                        <ErrorMessage attribute={t('frontend:payment_method')} error={errors.payment_method} />
                      </InputGroup>

                      <InfoMessage variant={email ? 'success' : 'alert'}>
                        <strong>
                          {email ? t('frontend:will_receive_payment_receipt') : t('frontend:will_not_receive_payment_receipt')}
                        </strong>
                        <div>
                          {email
                            ? t('frontend:will_receive_payment_receipt_description')
                            : t('frontend:will_not_receive_payment_receipt_description')}
                        </div>
                      </InfoMessage>
                    </>
                  )}
                </div>

                <table className="w-full">
                  <tbody>
                    <tr>
                      <td className="w-3/4">
                        {t('frontend:donation')}
                      </td>
                      <td className={classNames('text-right', !amount && 'text-slate-500')}>
                        {formatCurrency(amount)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          onClick={() => setShowPlatformDescription((show) => !show)}
                          className="text-slate-900 hover:text-slate-600"
                          role="button"
                        >
                          {t('frontend:fee')}
                          <Icon className="ml-2">
                            <HelpCircle />
                          </Icon>
                        </a>
                      </td>
                      <td className={classNames('text-right', !amount && 'text-slate-500')}>
                        {formatCurrency(platformFee)}
                      </td>
                    </tr>
                    <tr className="font-bold">
                      <td>
                        {t('frontend:amount_due')}
                      </td>
                      <td className={classNames('text-right', !amount && 'text-slate-500')}>
                        {formatCurrency(totalAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <FadeIn
                  when={showPlatformDescription}
                  render={() => (
                    <InfoMessage>
                      <h5 className="font-bold">
                        {t('frontend:platform_fee_title')}
                      </h5>
                      <p>
                        {t('frontend:platform_fee_explanation', { platformFee: formatCurrency(platformFee) })}
                      </p>
                    </InfoMessage>
                  )}
                />
              </>
            )}

            <div>
              <Button
                type="submit"
                loading={donating}
                className="block w-full"
                size="lg"
                disabled={!canReceivePayments || !amount}
              >
                <Icon className="mr-2">
                  <Lock />
                </Icon>
                {frequency === 'oneoff' && t('frontend:donate')}
                {frequency === DonationFrequency.Monthly && t('frontend:donate_monthly')}
                {frequency === DonationFrequency.Yearly && t('frontend:donate_yearly')}
              </Button>
              <ErrorMessage message={errors.root?.payment_failed?.message} />
            </div>

            <div className="p-6 space-y-2 text-center sm:space-y-6 bg-gradient-to-tl from-project/10 to-project/5 rounded-md sm:p-8">
              {charity.logo_url && (
                <img src={charity.logo_url} className="block h-8 mx-auto" />
              )}
              <div className="whitespace-pre-line">
                <Trans
                  i18nKey={frequency === DonationFrequency.Monthly
                    ? 'frontend:monthly_donation_confirmation'
                    : (frequency === DonationFrequency.Yearly
                      ? 'frontend:yearly_donation_confirmation'
                      : 'frontend:oneoff_donation_confirmation')}
                  values={{
                    charity: charity.title,
                    amount: formatCurrency(donationAmount),
                  }}
                >
                  <strong />
                </Trans>
              </div>
            </div>

          </div>
        </form>
      </Container>

      <Footer charity={charity} />
    </>
  );
}

DonationFormPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
