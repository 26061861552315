import { SVGProps } from 'react';

const SupportaSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.78 17.32" {...props}>
    <g>
      <path
        d="m19.17,1.61c-1.04-1.04-2.41-1.61-3.88-1.61s-2.84.57-3.89,1.61l-4.87,4.92c-.57.57-1.51.57-2.08,0-.28-.28-.43-.65-.43-1.04s.15-.76.43-1.04c.35-.35.84-.49,1.29-.41.64.11,1.29-.09,1.75-.55l.02-.02c1.12-1.12.56-3.07-.99-3.37-1.73-.33-3.58.17-4.91,1.51-1.04,1.04-1.61,2.41-1.61,3.88,0,1.39.52,2.69,1.45,3.71l.33.33c2.15,1.98,5.51,1.93,7.6-.16l4.87-4.92c.56-.56,1.53-.56,2.08,0,.28.28.43.65.43,1.04s-.15.76-.43,1.04l-7.36,7.36c-.78.78-.78,2.04,0,2.82l.02.02c.78.78,2.04.78,2.82,0l7.36-7.36c1.04-1.04,1.61-2.41,1.61-3.88s-.57-2.84-1.61-3.88Z"
        fill="currentColor" />
    </g>
  </svg>
);

export default SupportaSymbol;
