import { Link, usePage } from '@inertiajs/react';
import classNames from 'classnames';
import { Globe } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Locale } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';

export function LocaleSelector() {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { props: { page } } = usePage<{ page?: { localized_urls?: { [locale in Locale]: string; }; }; }>();

  const locales = [Locale.EN, Locale.NL];

  const getLocaleHref = (newLocale: Locale) => {
    if (page?.localized_urls?.[newLocale]) {
      return page?.localized_urls[newLocale];
    }

    const url = new URL(window.location.href);

    // Check if the first segment matches the current locale.
    if (url.pathname.replace(/^\//, '').split('/')[0] === locale) {
      url.pathname = `/${newLocale}${url.pathname.substring(3)}`;
    } else {
      url.searchParams.set('locale', newLocale);
    }

    return url.toString();
  };

  return (
    <div className="space-x-2">
      {locales.map((value) => (
        <Link
          href={getLocaleHref(value)}
          key={value}
          className={classNames(
            'inline-block px-2 py-1.5 border rounded-md',
            value === locale ? 'text-slate-500 hover:text-slate-500 font-medium' : 'text-slate-800 border-transparent'
          )}
        >
          {value === locale && (
            <Icon className="mr-1">
              <Globe />
            </Icon>
          )}
          {t(`shared:locales.${value}`)}
        </Link>
      ))}
    </div>
  );
}
