import { Link } from '@inertiajs/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Charity } from '../../Shared/types';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useRouter } from '../router';
import { NavItem } from '../types';
import Container from './Container';
import { EmbedContext } from './EmbedProvider';
import { LocaleSelector } from './LocaleSelector';

export interface FooterLink {
  title: string;
  url: string;
}

export function Footer({
  links,
  charity,
}: {
  links?: NavItem[];
  charity?: Charity | null,
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { locale } = useLocale();
  const { isEmbedded } = useContext(EmbedContext);

  const linkProps = isEmbedded ? {
    target: '_blank',
    rel: 'noopener noreferrer',
  } : {};

  const LinkComponent = isEmbedded ? 'a' : Link;

  return (
    <>
      {links && (
        <div className="border-t border-slate-200">
          <Container size="lg" spacing="lg" className="leading-loose">
            <div className="grid sm:grid-cols-3 gap-4 sm:gap-8  sm:text-left">
              <div>
                <h5 className="text-slate-500 font-medium mb-1">
                  {t('frontend:homepage.supporta')}
                </h5>
                <ul>
                  <li>
                    <LinkComponent href={routes.home_page()} {...linkProps}>
                      {t('frontend:homepage.campaigns')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.charities_page()} {...linkProps}>
                      {t('frontend:homepage.charities')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.events_page()} {...linkProps}>
                      {t('frontend:homepage.events')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.select_charity_page()} {...linkProps}>
                      {t('frontend:start_campaign')}
                    </LinkComponent>
                  </li>
                </ul>
              </div>
              {links.length > 0 && (
                <div>
                  <h5 className="text-slate-500 font-medium mb-1">
                    {t('frontend:homepage.about')}
                  </h5>
                  <ul>
                    {links.map((link, index) => (
                      <li key={index}>
                        <Link href={link.url}>
                          {link.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div>
                <h5 className="text-slate-500 font-medium mb-1">
                  {t('frontend:homepage.information')}
                </h5>
                <ul>
                  <li>
                    <a href={`https://about.supporta.com/${locale}`} {...linkProps}>
                      {t('frontend:info_for_charities')}
                    </a>
                  </li>
                  <li>
                    <LinkComponent href={routes.content_privacy_page()} {...linkProps}>
                      {t('frontend:privacy_statement')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.content_terms_page()} {...linkProps}>
                      {t('frontend:terms_of_service')}
                    </LinkComponent>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      )}
      <div className="border-t border-slate-200">
        <Container size="lg" spacing="md" className="text-sm">
          {((charity && !isEmbedded) || !links) && (
            <ul className="sm:text-left sm:flex space-x-0 sm:space-x-6 space-y-2 sm:space-y-0">
              {charity && !isEmbedded && (
                <>
                  <li>
                    <LinkComponent href={routes.home_page(charity.public ? charity.slug : null)} {...linkProps}>
                      {t('frontend:all_campaigns')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.select_charity_page(charity.public ? charity.slug : null)} {...linkProps}>
                      {charity.public && t('frontend:raise_money_for_charity', { charity: charity.title })}
                      {!charity.public && t('frontend:raise_money_for_a_charity')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.help_page()} {...linkProps} >
                      {t('frontend:help')}
                    </LinkComponent>
                  </li>
                </>
              )}
              {!links && (
                <>
                  <li>
                    <LinkComponent href={routes.content_privacy_page()} {...linkProps}>
                      {t('frontend:privacy_statement')}
                    </LinkComponent>
                  </li>
                  <li>
                    <LinkComponent href={routes.content_terms_page()} {...linkProps}>
                      {t('frontend:terms_of_service')}
                    </LinkComponent>
                  </li>
                </>
              )}
            </ul>
          )}

          <div className="flex items-center justify-between">
            <a
              href="https://supporta.com"
              rel="noopener noreferrer"
              className="text-slate-300 hover:text-emerald-500 transition-colors"
              title="Supporta"
              {...linkProps}
            >
              <SupportaSymbol className="w-4 inline-block" />
            </a>
            <LocaleSelector />
          </div>
        </Container>
      </div>
    </>
  );
}
